import React, { useState } from 'react';
import './style.css'

const LGPD = () => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    // Define a data de expiração do cookie para daqui a 1 ano
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);

    // Define o cookie com o nome 'cookieConsent' que expira em 1 ano
    document.cookie = `cookieConsent=true; expires=${expiryDate.toUTCString()}; path=/`;

    // Atualiza o estado para mostrar que o usuário aceitou
    setAccepted(true);
  };

  // Verifica se o usuário já aceitou o cookie
  if (accepted || document.cookie.includes('cookieConsent=true')) {
    return null; // Não renderiza o popup se o usuário já aceitou
  }

  return (
    <div className="cookie-popup-overlay">
      <div className="cookie-popup">
        <div className="cookie-popup-content">
          <h3>Políticas de Privacidade e Termos de Uso do Site</h3>
          <p>Ao utilizar este site, você concorda com as Políticas quanto à utilização de <br/>
            <a href='/termos-de-privacidade' alt='Políticas de Privavidade'>Dados e Privacidade</a> e concorda com os <a href='/termos-de-privacidade' alt='Políticas de Privavidade'>Termos de Uso</a> do Site.</p>
          <button type="button" className='btn btn-primarybtn btn-primary waves-effect waves-light' onClick={handleAccept}>Aceitar</button>
        </div>
      </div>
    </div>
  );
};

export default LGPD;
