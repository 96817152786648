import React from "react";
import bgCTA from "../../../assets/img/front-pages/backgrounds/cta-bg-light.png";
import dashboard from "../../../assets/img/front-pages/landing-page/cta-dashboard.png";

export default function Sobre() {
  return (
    <div data-bs-spy="scroll" className="scrollspy-example">

      <section id="hero-animation">
        <div id="landingHero" className="section-py landing-hero position-relative" style={{ paddingTop: '200px' }}>
          <div className="container">
            <div className="hero-text-box text-center">
              <h1 className="text-primary display-6 fw-bold">O Instituto Métricas</h1>
              <h2 className="hero-sub-title h6 mb-4 pb-1">
                Nossa <span className="fw-bold text-primary">MISSÃO</span> é promover o desenvolvimento de organizações <br className="d-none d-lg-block" />
                públicas e privadas por meio de ações de pesquisa, estudos e assistência técnica<br className="d-none d-lg-block" />
              </h2>
            </div>
          </div>
        </div>
      </section>

      <section id="oQueFazemos" style={{ marginTop: '143px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                <h3 className="card-header bg bg-primary text-white">O que Fazemos?</h3>
                <div className="card-body mt-5">
                  <p>Somos um centro de inovação com três principais frentes de ação, integradas e geradoras de posicionamentos:
                    pesquisa e produção de conhecimento, desenvolvimento de componentes para políticas de saúde pública e gestores de projetos.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card">
                <h3 className="card-header bg bg-primary text-white">Como Atuamos?</h3>
                <div className="card-body pe-5">
                  <h6 className="mt-5">Pesquisa e Desenvolvimento</h6>
                  <p>No Instituto Métricas, utilizamos informações e demonstrações estatísticas e probabilísticas provenientes de estudos e pesquisas para capacitar organizações a se adaptarem às realidades e demandas do mercado.</p>
                  <p>Nosso trabalho permite que essas organizações tomem decisões estratégicas voltadas para o futuro, melhorando seu desempenho e a qualidade dos serviços prestados.</p>
                  <p>Além disso, garantimos que estejam devidamente capacitadas para realizar seus projetos com eficácia, demonstrar a efetividade de seus resultados e alcançar máxima eficiência em termos de transparência.</p>
                  <h6 className="mt-5">Vantagem Competitiva</h6>
                  <p>No Instituto Métricas, fornecemos conhecimento especializado de área, mercado e tendências. Nosso diferencial é o foco exclusivo em empresas privadas, oferecendo ampla informação e análise de dados para esse público-alvo.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="qualidades" style={{ marginTop: '143px' }}>
        <div className="container">
          <div className="row mb-5">
            <div className="col-6">
              <div className="card border-1 border-label-primary mb-3">
                <div className="row g-0">
                  <div className="col-md-3">
                    <img className="card-img card-img-left" src={"https://images.pexels.com/photos/236380/pexels-photo-236380.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"} alt="Card " style={{ objectFit: 'cover', maxHeight: '213px', height: '100%' }} />
                  </div>
                  <div className="col-md-9">
                    <div className="card-body">
                      <h5 className="card-title">Atuação na Saúde</h5>
                      <p className="card-text">Destacamos nossa atuação na área da saúde, onde possuímos vasta experiência no desenvolvimento de credenciamento e habilitação de novos serviços, além da gestão de convênios.</p>
                      <p className="card-text">Garantimos a validação e autenticação eficiente dos projetos, fortalecendo a confiança e a transparência em todas as nossas ações.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="card border-1 border-label-primary mb-3">
                <div className="row g-0">
                  <div className="col-md-9">
                    <div className="card-body">
                      <h5 className="card-title">Relações Governamentais e Institucionais</h5>
                      <p className="card-text">Atuamos fortemente em advocacy e mobilização para captação de recursos públicos e formação de parcerias público-privadas.</p>
                      <p className="card-text">Através de nossas estratégias de advocacy, adaptamos às políticas públicas e decisões governamentais, assegurando que os interesses de nossos parceiros sejam representados de maneira eficaz.</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <img className="card-img card-img-right" src={"https://images.pexels.com/photos/13640268/pexels-photo-13640268.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"} alt="Card " style={{ objectFit: 'cover', maxHeight: '213px', height: '100%' }} />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>


      <section id="landingCTA" className="section-py landing-cta position-relative p-lg-0 pb-0" style={{ marginTop: '143px' }}>
        <img src={bgCTA} className="position-absolute bottom-0 end-0 scaleX-n1-rtl h-100 w-100 z-n1" alt="cta"/>
          <div className="container">
            <div className="row align-items-center gy-5 gy-lg-0">
              <div className="col-lg-6 text-center text-lg-start">
                <h6 className="h2 text-primary fw-bold mb-1">Pronto para Iniciar?</h6>
                <p className="fw-medium mb-4">Dê o start que você precisa em seus projetos com nosso gerenciador de projetos</p>
                <a href="payment-page.html" className="btn btn-lg btn-primary waves-effect waves-light">Solicitar Demonstração</a>
              </div>
              <div className="col-lg-6 pt-lg-5 text-center text-lg-end">
                <img src={dashboard} alt="cta dashboard" className="img-fluid"/>
              </div>
            </div>
          </div>
      </section>

    </div>
  )
}