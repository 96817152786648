export const Dados = {
    id: 1,
    nomeEntidade: 'IRMANDADE DA SANTA CASA DE MISERICÓRDIA DE ITATIBA',
    cnpj: '50.119.585/0001-31',
    ie: 'Isento',
    endereco: 'AVENIDA SAUDADE, 43',
    complemento: null,
    bairro: 'CENTRO',
    cep: '13253-000',
    municipio: 'Itatiba',
    uf: 'SP',
    email: 'santacasa@santacasaitatiba.org.br',
    telefone: '(11) 3333-0000',
    dataCriacao: '24/12/2022',
    dataAtualizacao: '24/12/2022',
    emailProcesso: 'licitacao@santacasadeitatiba.org.br',
    telefoneProcesso: '(11) 3333-0000',
    usuarios: [{
        id: 1,
        nomeCompleto: 'Francisca Elenística da Silva',
        cpf: '123.123.123-00',
        email: 'francisca@santacasaitatiba.org.br',
        senha: '123456',
        role: 'gestor',
        dataCriacao: '24/12/2022',
        dataAtualizacao: '24/12/2022',
    },{
        id: 2,
        nomeCompleto: 'Renato Cariani',
        cpf: '321.321.321-00',
        email: 'renato@santacasaitatiba.org.br',
        senha: '123456',
        role: 'staff',
        dataCriacao: '24/12/2022',
        dataAtualizacao: '24/12/2022',
    }],
    processos: [{
        id: 1,
        ano: 2024,
        anoInstrumento: 2024,
        codigoMunicipio: 123456,
        cpfResponsavelHomologacao: '123.123.123-00',
        cpfUsuario: '123.123.123-00',
        criterioJulgamento: 'MP', // MP – Menor Preço, MT – Melhor Técnica, TP – Técnica e Preço, MD – Maior Desconto, MR – Maior Retorno Econômico, ML – Maior Lance, CN – Concurso, CA – Conteúdo Artístic
        dataAberturaLicitacao: '2024/03/02', //Formato: YYYY-MM-DD.
        dataEncerramentoLicitacao: '2024/03/02', //Formato: YYYY-MM-DD.
        dataHomologacao: '', //Formato: YYYY-MM-DD.
        dataPublicacaoEdital: '2024/03/02', //Formato: YYYY-MM-DD.
        dataSolRecDispensa: '',  //Formato: YYYY-MM-DD.
        formaCompra: 'SRP', //SISPP ou SRP
        formaRealizacao: 'E', // E - Eletrônica ou P - Presencial
        inciso: '', //Exclusivo para Dispensas e Inexigibilidades
        itens: [
            {
            id: 1,
            descricao: 'Aparelho de anestesia',
            descricaoCompleta: '<h1>Lorem ipsum dolor sit amet</h1> <p>consectetur adipiscing elit. Duis eu justo sodales, sagittis orci non, lobortis elit.</p> <p>Sed tempor finibus commodo. Fusce vel bibendum eros. Aliquam pellentesque odio at mattis placerat. Nullam vel nisl a eros aliquam lobortis. Cras vitae odio vel massa luctus mollis. Praesent ullamcorper leo in tincidunt feugiat. Donec pulvinar neque eget molestie mattis. Curabitur a lacus mauris. Etiam vel tempor mauris. Maecenas elit justo, eleifend ut velit id, finibus vulputate mi. Etiam consectetur sodales nulla ut accumsan. Ut sagittis turpis non dictum pretium. Quisque mollis lacinia eros.</p>',
            valorUnitario: 40000,
            valorTotal: 200000,
            fornecedores: [
                {
                    id: 1,
                    fabricante: "Dragger",
                    identificador: "xxxxxx",
                    marca: "Dragger",
                    posicao: 1,
                    quantidadeOfertada: 5,
                    razaoSocial: "Dragger Industria de Equipamentos",
                    tipoFornecedor: "xxxxxxx",
                    valorDesconto: 5000,
                    valorTotal: 195000,
                    valorUnitario: 39000,
                    vencedor: true
                },
            ],
            numeroItem: 1,
            numeroLoteItem: '', //não obrigatório,
            quantidadeSolicitada: '99,99',
            situacaoItem: 'EM_ANDAMENTO',
            situacaoItemMaisBrasil: 'EM_ANDAMENTO', // EM_ANDAMENTO ou CONCLUIDO
            tipoItem: 'MATERIAL', //MATERIAL OU SERVIÇO
            unidadeFornecimento: 'UNIDADE'
        },{
            id: 2,
            descricao: 'Aparelho de anestesia',
            descricaoCompleta: '<h1>Lorem ipsum dolor sit amet</h1> <p>consectetur adipiscing elit. Duis eu justo sodales, sagittis orci non, lobortis elit.</p> <p>Sed tempor finibus commodo. Fusce vel bibendum eros. Aliquam pellentesque odio at mattis placerat. Nullam vel nisl a eros aliquam lobortis. Cras vitae odio vel massa luctus mollis. Praesent ullamcorper leo in tincidunt feugiat. Donec pulvinar neque eget molestie mattis. Curabitur a lacus mauris. Etiam vel tempor mauris. Maecenas elit justo, eleifend ut velit id, finibus vulputate mi. Etiam consectetur sodales nulla ut accumsan. Ut sagittis turpis non dictum pretium. Quisque mollis lacinia eros.</p>',
            valorUnitario: 40000,
            valorTotal: 200000,
            fornecedores: [
                {
                    id: 1,
                    fabricante: "Dragger",
                    identificador: "xxxxxx",
                    marca: "Dragger",
                    posicao: 1,
                    quantidadeOfertada: 5,
                    razaoSocial: "Dragger Industria de Equipamentos",
                    tipoFornecedor: "xxxxxxx",
                    valorDesconto: 5000,
                    valorTotal: 195000,
                    valorUnitario: 39000,
                    vencedor: true
                },
            ],
            numeroItem: 2,
            numeroLoteItem: '', //não obrigatório,
            quantidadeSolicitada: '99,99',
            situacaoItem: 'EM_ANDAMENTO',
            situacaoItemMaisBrasil: 'EM_ANDAMENTO', // EM_ANDAMENTO ou CONCLUIDO
            tipoItem: 'MATERIAL', //MATERIAL OU SERVIÇO
            unidadeFornecimento: 'UNIDADE'
        },
    ],
        justificativa: '',
        legislacao: '',
        linkEdital: '',
        modalidade: '',
        numero: '24',
        numeroInstrumento: '888888',
        numeroProcesso: '24/2024',
        objeto: "string",
        origemRecursoFinanceiro: "string",
        situacao: "string",
        situacaoMaisBrasil: "string",
        tipoInstrumento: "string",
        tipoObjeto: "string",
        tipoTransferencia: "string",
        uf: "string",
        valorGlobal: 500000,
        status: 'Em elaboração', //Em elaboração, Concluído, Não iniciado,
        publicacao: 'Não Publicado', //Não Publicado, Aguardando Publicação, Publicado, Finalizado
        categoria: 'Equipamentos', //Obras, Equipamentos, Custeio
        dataCriacao: '24/12/2022',
        dataAtualizacao: '24/12/2022',
    }]
}