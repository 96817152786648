// PerfilContexto.js
import React, { createContext, useState, useEffect } from 'react';

// Criar o contexto
const PerfilContexto = createContext();

const ProvedorDeContexto = ({ children }) => {
  const [perfil, setPerfil] = useState(null);

  useEffect(() => {
    // Ler o perfil do localStorage na montagem do componente
    const perfilArmazenado = localStorage.getItem('prl');
    if (perfilArmazenado) {
      setPerfil(perfilArmazenado);
    }
  }, []);

  return (
    <PerfilContexto.Provider value={{ perfil, setPerfil }}>
      {children}
    </PerfilContexto.Provider>
  );
};

export { PerfilContexto, ProvedorDeContexto };
