/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import axios from "axios";
import React, { useState } from "react";
import { apiURL } from "../../config/s";
import LoadingMenor from "../../componentes/Loading/LoadingMenor";
import { useNavigate } from "react-router-dom";
import '../../assets/vendor/libs/@form-validation/form-validation.css'
import '../../assets/vendor/css/pages/page-auth.css'

export default function Registro() {

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');
    const [senha, setSenha] = useState('');
    const [confirmaSenha, setConfirmaSenha] = useState('');
    const [error, setError] = useState('');
    const [senhaMatchError, setSenhaMatchError] = useState('');
    const [carregando, setCarregando] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (senha !== confirmaSenha) {
            setSenhaMatchError('As senhas não coincidem');
            return;
        }
        try {
            await postCadastro(nome, email, cpf, senha);
        } catch (error) {
            setError(error.response?.data?.error || 'Erro ao processar sua solicitação');
        }
    };

    const postCadastro = async (nome, email, cpf, senha) => {
        try {
            setCarregando(true);
            const response = await axios.post(`${apiURL}/usuarios/frontend`, {
                nomeCompleto: nome,
                email,
                cpf,
                senha,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response && response.data && response.data.mensagem) {
                navigate('/');
            }
        } catch (error) {
            setError(error.response?.data?.error || 'Erro ao processar sua solicitação');
        } finally {
            setCarregando(false);
        }
    };

    return (
        <div className="authentication-wrapper authentication-cover authentication-bg">
            <div className="authentication-inner row">
                <div className="d-none d-lg-flex col-lg-7 p-0">
                    <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                        <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/auth-register-illustration-light.png"
                            alt="auth-login-cover"
                            className="img-fluid my-5 auth-illustration"
                        />

                        <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/bg-shape-image-light.png"
                            alt="auth-login-cover"
                            className="platform-bg"
                        />
                    </div>
                </div>
                <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
                    <div className="w-px-400 mx-auto">
                        <div className="app-brand mb-4">
                            <a href="/" className="app-brand-link gap-2">
                                <span className="app-brand-logo demo">
                                    <svg width="32" height="22" viewBox="0 0 32 22" fillRule="none" xmlns="http://www.w3.org/2000/svg" style={{ rotate: "180deg" }}>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z" fill="#7367F0"></path>
                                        <path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z" fill="#161616"></path>
                                        <path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z" fill="#161616"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z" fill="#7367F0"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>

                        <h3 className="mb-4">Sua aventura começa aqui 🚀</h3>

                        {error && <div className="alert alert-danger" role="alert">{error}</div>}
                        {senhaMatchError && <div className="alert alert-danger" role="alert">{senhaMatchError}</div>}

                        {
                            carregando ? <LoadingMenor /> : (
                                <form id="formAuthentication" className="mb-3 fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate" onSubmit={handleSubmit}>
                                    <div className="mb-3 fv-plugins-icon-container">
                                        <label htmlFor="username" className="form-label">Nome Completo</label>
                                        <input type="text" className="form-control" id="nomeCompleto" name="nomeCompleto" placeholder="Digite seu nome completo" autoFocus="" onChange={(e) => setNome(e.target.value)} />
                                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                    </div>
                                    <div className="mb-3 fv-plugins-icon-container">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input type="text" className="form-control" id="email" name="email" placeholder="Digite o seu email" onChange={(e) => setEmail(e.target.value)} />
                                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                    </div>
                                    <div className="mb-3 fv-plugins-icon-container">
                                        <label htmlFor="cpf" className="form-label">CPF</label>
                                        <input type="text" className="form-control" id="cpf" name="cpf" placeholder="000.000.000-00" onChange={(e) => setCpf(e.target.value)} />
                                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                    </div>
                                    <div className="mb-3 form-password-toggle fv-plugins-icon-container">
                                        <label className="form-label" htmlFor="password">Senha</label>
                                        <div className="input-group input-group-merge has-validation">
                                            <input type="password" id="password" className="form-control" name="password" placeholder="············" aria-describedby="password" onChange={(e) => setSenha(e.target.value)} />
                                            <span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>
                                        </div>
                                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                    </div>
                                    <div className="mb-3 form-password-toggle fv-plugins-icon-container">
                                        <label className="form-label" htmlFor="passwordConfirmation">Confirmar Senha</label>
                                        <div className="input-group input-group-merge has-validation">
                                            <input type="password" id="passwordConfirmation" className="form-control" name="passwordConfirmation" placeholder="············" aria-describedby="passwordConfirmation" onChange={(e) => setConfirmaSenha(e.target.value)} />
                                            <span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>
                                        </div>
                                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                    </div>

                                    <div className="mb-3 fv-plugins-icon-container">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="terms-conditions" name="terms" />
                                            <label className="form-check-label" htmlFor="terms-conditions">
                                                Eu concordo com as
                                                <a href="#">Políticas de Privacidade &amp; Termos de Uso</a>
                                            </label>
                                            <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
                                    </div>
                                    <button className="btn btn-primary d-grid w-100 waves-effect waves-light">
                                        Entrar
                                    </button>
                                    <input type="hidden" />
                                </form>
                            )
                        }

                        <p className="text-center">
                            <span>Já possui uma conta?</span>
                            <a href="/login">
                                &nbsp;<span>Login</span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}