import React from "react";

export default function TermosPrivacidade() {
    return (
        <div data-bs-spy="scroll" className="scrollspy-example">

            <section id="hero-animation">
                <div id="landingHero" className="section-py landing-hero position-relative" style={{ paddingTop: '200px' }}>
                    <div className="container">
                        <div className="hero-text-box text-center">
                            <h1 className="text-primary display-6 fw-bold">Termos de Privacidade e Uso de Dados Privados</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section id="qualidades" style={{ marginTop: '143px' }}>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-8">
                            <div className="card border-1 border-label-primary mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">Aceite do Termo de Utilização do Site</h5>
                                    <p>1.&nbsp;&nbsp;INTRODUÇÃO&nbsp;&nbsp;&nbsp;</p>
                                    <p>o Instituto MÉTRICAS, inscrita no CNPJ/MF sob o nº 52.399.502/0001-30, com sede na General Carneiro, 1244, bairro Bom Jardim, CEP 14.340-000, na cidade de Brodowski, estado de São Paulo, prezando pela segurança dos clientes e parceiros que acessam suas plataformas digitais e utilizam seus canais de atendimento e vendas, reforça o compromisso de continuar respeitando e garantindo a privacidade e a proteção dos dados pessoais de quem quer que seja.</p>
                                    <p>Movida pela ética, respeito à privacidade dos titulares, pelo cumprimento da Lei e tendo plena ciência da importância da legislação vigente sobre a proteção de dados pessoais e riscos envolvidos em eventual violação, o Instituto Métricas assume o dever e o compromisso de proteção a esses temas e, justamente por esse motivo, implanta a presente Política de Privacidade e Proteção de Dados Pessoais.</p>
                                    <p>O objetivo deste documento é fornecer informações claras, facilmente compreensíveis e precisas sobre a coleta e tratamento de dados pessoais realizados pelo Instituto Métricas em seu site e canais de atendimento. Além disso, identifica o Encarregado de Proteção de Dados (DPO) da empresa e fornece suas informações de contato para qualquer tipo de esclarecimento que se faça necessário por parte de autoridades fiscalizadoras e titulares de dados.</p>
                                    <p>2.&nbsp;&nbsp;INFORMAÇÕES GERAIS</p>
                                    <p>O Instituto Métricas disponibiliza aos usuários o site institutometricas.org.br para fornecer informações corporativas, de serviços, produtos e mercado a clientes e parceiros. Além disso, utiliza seus canais de atendimento e vendas para entender e atender as necessidades de seus clientes.</p>
                                    <p>No exercício dessas atividades, empregamos meditas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, extravio ou alteração. Tais dados são armazenados em banco de dados do Instituto Métricas, que dispõe de sistemas de segurança e monitoramento bastante robustos, compatíveis com os padrões do mercado brasileiro e internacional.</p>
                                    <p>Processamos os dados pessoais em plena conformidade com a Lei Geral de Proteção de Dados (LGPD) e demais regulamentações aplicáveis. Sob nenhuma hipótese compartilhamos os dados pessoais com terceiros, sendo que os utilizamos para fins exclusivos de marketing, cadastramento, elaboração de contratos e emissão de notas fiscais.</p>
                                    <p>3.&nbsp;&nbsp;DADOS PESSOAIS COLETADOS E SUA UTILIZAÇÃO</p>
                                    <p>O Instituto Métricas coleta dados pessoais não sensíveis compartilhados pelo usuário ao utilizar o site, quando o mesmo, por exemplo, preenche nosso Formulário de Contato ou, automaticamente, enquanto navega pelas páginas do site. Além disso, por meio dos nossos canais de atendimento e plataformas de gestão, também são coletados dados pessoais não sensíveis para cadastramento, posterior elaboração de contrato e emissão de nota fiscal.</p>
                                    <p>O Instituto Métricas realiza a coleta e tratamento desses dados nas seguintes hipóteses:</p>
                                    <p>&nbsp;&nbsp;a.&nbsp;&nbsp;Consentimento do titular: Manifestação livre e inequívoca do usuário ou cliente informando que os dados pessoais podem ser coletados e tratados.</p>
                                    <p>&nbsp;&nbsp;b.&nbsp;&nbsp;Cumprimento de obrigação legal ou regulatória pelo controlador: Algumas operações de tratamento de dados pessoais serão realizadas para que possamos cumprir com as obrigações previstas em leis ou em outras disposições normativas aplicadas às nossas atividades.</p>
                                    <p>O usuário ou cliente poderá se recusar a submeter dados pessoais ao site ou canais do Instituto Métricas. Nesse caso, o fornecimento de produtos e serviços pode deixar de ser possível. Além disso, a veracidade dos dados pessoais compartilhados pelo usuário ou cliente é de sua exclusiva responsabilidade, razão pela qual se requer extremo cuidado e atenção ao informá-los.</p>
                                    <p>4.&nbsp;&nbsp;TEMPO DE TRATAMENTO DOS DADOS PESSOAIS</p>
                                    <p>Os dados pessoais coletados pelo site e pelos nossos canais de atendimento serão armazenados e utilizados pelo período de tempo que corresponda ao necessário para atingir as finalidades elencadas nesse documento e que considere os direitos de seus titulares, os direitos do controlador e as disposições legais aplicáveis.</p>
                                    <p>Uma vez expirado o período de armazenamento dos dados pessoais, eles são removidos das nossas bases de dados, salvo nos casos em que houver a possibilidade ou necessidade de armazenamento em virtude de disposição legal ou regulatória.</p>
                                    <p>5.&nbsp;&nbsp;SEGURANÇA DOS DADOS PESSOAIS</p>
                                    <p>O Instituto Métricas adota as medidas apropriadas para garantir a segurança dos dados pessoais coletados. Essas medidas incluem, mas não se limitam à:</p>
                                    <p>&nbsp;&nbsp;a.&nbsp;&nbsp;Utilização de ambiente virtual seguro;</p>
                                    <p>&nbsp;&nbsp;b.&nbsp;&nbsp;Implementação de políticas de acesso digital e físico;</p>
                                    <p>&nbsp;&nbsp;c.&nbsp;&nbsp;Implementação de trilhas de auditoria para monitoramento e rastreabilidade dos acessos;</p>
                                    <p>&nbsp;&nbsp;d.&nbsp;&nbsp;Revisão de procedimentos internos e capacitação de pessoal.</p>
                                    <p>Ressaltamos que a utilização dos dados pessoais coletados é 100% interna, não existindo possibilidade de compartilhamento dos mesmos com terceiros não autorizados.</p>
                                    <p>Tentativas de invasão ao site do Instituto Métricas ou a qualquer um de nossos ambientes informatizados serão entendidas e tratadas como atitudes ilícitas e serão conduzidas conforme o previsto na legislação civil e penal em vigor.</p>
                                    <p>&nbsp;&nbsp;6.&nbsp;&nbsp;DIREITOS DOS TITULARES DE DADOS</p>
                                    <p>Os titulares de dados coletados pelo Instituto Métricas possuem os seguintes direitos conferidos pela legislação vigente:</p>
                                    <p>&nbsp;&nbsp;a.&nbsp;&nbsp;Confirmação da existência de tratamento;</p>
                                    <p>&nbsp;&nbsp;b.&nbsp;&nbsp;Acesso aos dados;</p>
                                    <p>&nbsp;&nbsp;c.&nbsp;&nbsp;Correção de dados incompletos, inexatos ou desatualizados;</p>
                                    <p>&nbsp;&nbsp;d.&nbsp;&nbsp;Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na lei;</p>
                                    <p>&nbsp;&nbsp;e.&nbsp;&nbsp;Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</p>
                                    <p>&nbsp;&nbsp;f.&nbsp;&nbsp;Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nos casos previstos em lei;</p>
                                    <p>&nbsp;&nbsp;g.&nbsp;&nbsp;Informação das entidades públicas e privadas com as quais o controlador realizou o uso compartilhado de dados;</p>
                                    <p>&nbsp;&nbsp;h.&nbsp;&nbsp;Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</p>
                                    <p>&nbsp;&nbsp;i.&nbsp;&nbsp;Revogação do consentimento.</p>
                                    <p>Os titulares de dados poderão solicitar a execução de qualquer dos direitos elencados acimas mediante a realização de contato formal com o Encarregado de Proteção de Dados (DPO) do Instituto Métricas identificado no item 8 deste documento.</p>
                                    <p>&nbsp;&nbsp;7.&nbsp;&nbsp;COOKIES</p>
                                    <p>Com o objetivo de oferecer a melhor experiência possível aos usuários do nosso site, utilizamos a tecnologia de armazenamento de Cookies.</p>
                                    <p>Os Cookies permitem que o website do Instituto Métricas armazene informações relevante sobre a visita do usuário, como, por exemplo, seu idioma preferido, localização, recorrência das suas sessões e outros dados que consideramos importantes para um atendimento excelente.</p>
                                    <p>Ao acessar o site do Instituto Métricas, o usuário autoriza o uso de Cookies. Caso não concorde com o uso de Cookies para a finalidade aqui apresentada, o usuário poderá ajustar as configurações do seu navegador para impedir seu uso.</p>
                                    <p>No entanto, ressaltamos que desabilitar o uso cookies poderá impactar na experiência de utilização do nosso site e impedir o funcionamento de alguns recursos disponíveis.</p>
                                    <p>8.&nbsp;&nbsp;DPO (ENCARREGADO DE PROTEÇÃO DE DADOS)</p>
                                    <p>O Encarregado de Proteção de Dados (DPO) do Instituto Métricas é:</p>
                                    <p>Diego Muniz</p>
                                    <p>contato@institutometricas.org.br</p>
                                    <p>Todos as solicitações e consultas relacionadas à privacidade dos dados coletados pelo Instituto Métricas deverão ser encaminhadas ao nosso DPO.</p>
                                    <p>9.&nbsp;&nbsp;ALTERAÇÕES E REVISÕES</p>
                                    <p>Esta é a versão original da Política de Privacidade e Proteção de Dados Pessoais, não havendo passado este documento por revisões até a presente data.</p>
                                    <p>No entanto, nos reservamos o direito de modificar, a qualquer momento, as presentes normas e diretrizes, especialmente para adaptá-las às eventuais alterações feitas em nosso site ou nos procedimentos internos. Sempre que houver alguma modificação, nossos clientes e parceiros serão notificados e terão acesso à versão atualizada desse documento.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">

                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}