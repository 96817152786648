export function limitarString(texto, limite) {
    // Remover todas as tags HTML da string
    const textoSemHTML = texto.replace(/<[^>]*>?/gm, '');

    if (textoSemHTML.length <= limite) {
        return textoSemHTML;
    } else {
        return textoSemHTML.substring(0, limite) + '...';
    }
}

export function transferegovMetricas(dataString) {
    // Dividir a string da data em partes (ano, mês, dia)
    const partes = dataString.split('/');

    // Rearranjar as partes na ordem DD/MM/AAAA
    const dataFormatoBrasileiro = partes[2] + '/' + partes[1] + '/' + partes[0];

    return dataFormatoBrasileiro;
}

export function formatarReal(numero) {
    // Verificar se o número é um número válido
    if (isNaN(numero)) return "Número inválido";

    // Converter para string e separar parte inteira da parte decimal
    const [parteInteira, parteDecimal] = numero.toString().split('.');

    // Formatando a parte inteira com separador de milhares
    const parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Concatenando a parte inteira formatada com a parte decimal
    const numeroFormatado = `R$ ${parteInteiraFormatada}${parteDecimal ? ',' + parteDecimal : ''}`;

    return numeroFormatado;
}

export function inicialMaiuscula(word) {
    if (typeof word !== 'string' || word.length === 0) {
        return '';
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function formatoDataHora(dateString, formatType = 'both') { //formatType = 'date' || 'time' || 'both'
    const date = new Date(dateString);

    // Verifica se a data é inválida
    if (isNaN(date)) {
        return 'Data inválida';
    }

    const options = {};

    if (formatType === 'date' || formatType === 'both') {
        options.day = '2-digit';
        options.month = '2-digit';
        options.year = 'numeric';
    }

    if (formatType === 'time' || formatType === 'both') {
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.second = '2-digit';
        options.hour12 = false; // Usa formato de 24 horas
    }

    const formatter = new Intl.DateTimeFormat('pt-BR', options);

    return formatter.format(date);
}


export function limitar30Palavras(textoHTML) {
    // Remover tags HTML do texto
    const textoSemTags = textoHTML.replace(/<[^>]*>?/gm, '');
    // Dividir o texto em palavras
    const palavras = textoSemTags.split(' ');
    // Extrair as primeiras 30 palavras
    const primeirasPalavras = palavras.slice(0, 30).join(' ');
    // Adicionar as tags HTML removidas novamente
    const textoFormatado = textoHTML.substring(0, textoHTML.indexOf(primeirasPalavras)) + primeirasPalavras;
    return (
        <div dangerouslySetInnerHTML={{ __html: textoFormatado + '...' }} />
    );
}

export function renderizarHTML(htmlString) {
    return (
        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
    );
};
  
