/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function LogoutBotao() {
    const navigate = useNavigate();

    const handleLogout = () => {
        
        localStorage.clear();
        
        navigate('/login');
    };

    return (
        <a className="btn btn-primary w-100 mb-3 waves-effect waves-light" href="/login" onClick={handleLogout}>
            Voltar
        </a>
    );
};
