/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";

import "../../assets/vendor/css/pages/page-auth.css";
import "../../assets/vendor/libs/bs-stepper/bs-stepper.css"
import "../../assets/vendor/libs/bootstrap-select/bootstrap-select.css"
import "../../assets/vendor/libs/select2/select2.css"
import "../../assets/vendor/libs/@form-validation/form-validation.css"
import RegistroPerfilFornecedor from "./RegistroPerfilFornecedor";
import RegistroPerfilEntidade from "./RegistroPerfilEntidade";
import { IconArrowRightToArc, IconCheck, IconForms, IconUser } from "@tabler/icons-react";
import { Navigate } from "react-router-dom";

export default function RegistroPerfilempresa() {
    const [ativo, setAtivo] = useState(null);
    const [activeKey, setActiveKey] = useState('tab1');
    const [perfil, setPerfil] = useState(null); // Alterei o valor inicial para null
    const [parametros, setParametros] = useState({})

    useEffect(() => {
        setAtivo(localStorage.getItem('prl'));
        if(ativo !== 'null') {
            <Navigate to={'/painel'} />
        }
    }, [ativo])

    const renderTabContent = () => {
        switch (activeKey) {
            case 'tab1':
                return <AbaUm etapa={handleEtapa} />;
            case 'tab2':
                if (perfil === 1) {
                    return <RegistroPerfilFornecedor dados={handleDados} />;
                } else if (perfil === 0) {
                    return <RegistroPerfilEntidade />;
                }
                break; // Adicionei um break para sair do switch quando activeKey é 'tab2'
            case 'tab3':
                return <Contratacao finalizar={handleFinalizar} />;
            default:
                return null;
        }
    };

    const handleEtapa = (valor) => {
        if (valor === 'Fornecedor') {
            setActiveKey('tab2');
            setPerfil(1);
        } else if (valor === 'Entidade') {
            setActiveKey('tab2'); // Alterei para 'tab2' para que vá para a próxima aba após selecionar 'Entidade'
            setPerfil(0);
        }
    };

    const handleDados = (valor) => {
        setParametros(valor);
        setActiveKey('tab3');
    };

    const handleFinalizar = (valor) => {
        console.log(valor);
    };

    useEffect(() => {
        console.log(parametros);
    },[parametros])

    return (
        <div className="authentication-wrapper authentication-cover authentication-bg">
            <div className="authentication-inner row">
                <div className="d-none d-lg-flex col-lg-4 align-items-center justify-content-center p-5 auth-cover-bg-color position-relative auth-multisteps-bg-height">
                    <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/auth-register-multisteps-illustration.png" alt="auth-register-multisteps" className="img-fluid" width="280" />
                    <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/bg-shape-image-light.png" alt="auth-register-multisteps" className="platform-bg" data-app-light-img="illustrations/bg-shape-image-light.png" data-app-dark-img="illustrations/bg-shape-image-dark.png" />
                </div>
                <div className="d-flex col-lg-8 align-items-center justify-content-center p-sm-5 p-3">
                    <div className="w-px-700">
                        <div className="bs-stepper shadow-none linear">
                            <div className="bs-stepper-header border-bottom-0">
                                <div className={`step ${activeKey === 'tab1' ? 'active' : ''}`} onClick={() => setActiveKey('tab1')}>
                                    <button type="button" className="step-trigger" aria-selected={activeKey === 'tab1'}>
                                        <span className="bs-stepper-circle"><IconUser size={16}/></span>
                                        <span className="bs-stepper-label">
                                            <span className="bs-stepper-title">Perfil</span>
                                            <span className="bs-stepper-subtitle">Tipo de empresa</span>
                                        </span>
                                    </button>
                                </div>
                                <div className="line">
                                    <IconArrowRightToArc size={21}/>
                                </div>
                                <div className={`step ${activeKey === 'tab2' ? 'active' : ''}`} onClick={() => setActiveKey('tab2')}>
                                    <button type="button" className="step-trigger" aria-selected={activeKey === 'tab2'}>
                                        <span className="bs-stepper-circle"><IconForms size={16}/></span>
                                        <span className="bs-stepper-label">
                                            <span className="bs-stepper-title">Formulário</span>
                                            <span className="bs-stepper-subtitle">Cadastro da empresa</span>
                                        </span>
                                    </button>
                                </div>
                                <div className="line">
                                    <IconArrowRightToArc size={21}/>
                                </div>
                                <div className={`step ${activeKey === 'tab3' ? 'active' : ''}`} onClick={() => setActiveKey('tab3')}>
                                    <button type="button" className="step-trigger" aria-selected={activeKey === 'tab3'}>
                                        <span className="bs-stepper-circle"><IconCheck size={16}/></span>
                                        <span className="bs-stepper-label">
                                            <span className="bs-stepper-title">Contratação</span>
                                            <span className="bs-stepper-subtitle">Aceite do contrato</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="tab-content mt-4">
                                <form id="multiStepsForm">
                                    {renderTabContent()}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function AbaUm({ etapa }) {
    const [tipoEmpresa, setTipoEmpresa] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        etapa(tipoEmpresa);
    };

    return (
        <div className="col-md p-4">
            <div className="content-header mb-4">
                <h3 className="mb-1">Perfil da Empresa</h3>
                <p>Escolha uma opção a seguir</p>
            </div>
            <form id="escolherPerfil">
                <div className="form-check mt-3">
                    <input
                        name="perfil"
                        className="form-check-input"
                        type="radio"
                        value="Fornecedor"
                        id="perfilFornecedor"
                        onChange={(e) => setTipoEmpresa(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="perfilFornecedor">
                        Fornecedor
                    </label>
                </div>
                <div className="form-check">
                    <input
                        name="perfil"
                        className="form-check-input"
                        type="radio"
                        value="Entidade"
                        id="perfilEntidade"
                        onChange={(e) => setTipoEmpresa(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="perfilEntidade">
                        Entidade
                    </label>
                </div>
                <div className="col-12 d-flex justify-content-between mt-4">
                    <button className="btn btn-primary btn-next waves-effect waves-light" onClick={handleSubmit}>
                        <span className="align-middle d-sm-inline-block d-none me-sm-1 me-0">Próximo</span>
                    </button>
                </div>
            </form>
        </div>
    );
}

function Contratacao({ finalizar }) {
    const [aceite, setAceite] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (aceite) {
            finalizar(true);
        } else {
            alert('Você precisa aceitar os termos para continuar.');
        }
    };

    return (
        <div className="col-md p-4">
            <div className="content-header mb-4">
                <h3 className="mb-1">Contratação</h3>
                <p></p>
            </div>
            <form id="aceitarTermos">
                <div className="form-check form-check-primary mt-3">
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        id="customCheckPrimary" 
                        checked={aceite} 
                        onChange={(e) => setAceite(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="customCheckPrimary">Eu aceito os termos</label>
                </div>
                <p className="mt-2">
                    Ao aceitar, você reconhece as <a href="/politicas-do-site" target="_blank">Políticas do Site</a> e os <a href="/termos-de-privacidade" target="_blank">Termos de Privacidade</a>, e está de acordo em utilizar a plataforma por um período de 7 dias grátis como amostra.
                </p>
                <div className="col-12 d-flex justify-content-between mt-4">
                    <button className="btn btn-primary btn-next waves-effect waves-light" type="submit" onClick={handleSubmit}>
                        <span className="align-middle d-sm-inline-block d-none me-sm-1 me-0">Finalizar</span>
                    </button>
                </div>
            </form>
        </div>
    );
}

