import React from "react";

import laptop from "../../../../assets/img/front-pages/icons/laptop.png";
import success from "../../../../assets/img/front-pages/icons/user-success.png";
import diamond from "../../../../assets/img/front-pages/icons/diamond-info.png";
import check from "../../../../assets/img/front-pages/icons/check-warning.png";
import transferegovpage from "../../../../assets/img/transferegov.png"
import { IconListDetails } from "@tabler/icons-react";

export default function Transferegov() {

    return (
        <div data-bs-spy="scroll" className="scrollspy-example">

            <section id="hero-animation">
                <div id="landingHero" className="section-py landing-hero position-relative" style={{ paddingTop: '200px' }}>
                    <div className="container">
                        <div className="hero-text-box text-center">
                            <h1 className="text-primary display-6 fw-bold">Transferegov</h1>
                            <h2 className="hero-sub-title h6 mb-4 pb-1">
                                Com nossa assessoria, a gestão do seu convênio será um sucesso e<br/>a prestação de contas será realizada com tranquilidade.
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section id="qualidades" style={{ marginTop: '143px' }}>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-lg-8">
                            <div className="card border-1 border-label-primary mb-3">
                                <div className="row g-0">
                                    <div className="col-md-3">
                                        <img className="card-img card-img-left" src={transferegovpage} alt="Card " style={{ objectFit: 'cover', maxHeight: '213px', height: '100%' }} />
                                    </div>
                                    <div className="col-md-9">
                                        <div className="card-body">
                                            <h5 className="card-title">Benefícios</h5>
                                            <p className="card-text">A Transferegov é uma plataforma digital desenvolvida pelo Governo Federal do Brasil para facilitar e agilizar a transferência de recursos públicos para estados, municípios e o Distrito Federal. Essa plataforma centraliza e automatiza os processos de transferências voluntárias da União, garantindo maior transparência, eficiência e controle na execução dos recursos públicos.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-body">
                                    <img className="card-img-top" src={"https://idp.transferegov.sistema.gov.br/idp/resources/imagens/producao/logo_portal.png"} alt="Transferegov logo"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="fatos" className="section-py landing-fun-facts">
                <div className="container">
                    <div className="row gy-3">
                        <div className="col-sm-6 col-lg-3">
                            <div className="card border border-label-primary shadow-none">
                                <div className="card-body text-center">
                                    <img src={laptop} alt="laptop" className="mb-2"/>
                                        <h5 className="h2 mb-1">Economia </h5>
                                        <p className="fw-medium mb-0">
                                            Conosco você pode<br/>
                                                economizar tempo e recurso
                                        </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card border border-label-success shadow-none">
                                <div className="card-body text-center">
                                    <img src={success} alt="laptop" className="mb-2"/>
                                        <h5 className="h2 mb-1">Experiência</h5>
                                        <p className="fw-medium mb-0">
                                            Equipe de especialistas<br/>
                                                em processo licitatório
                                        </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card border border-label-info shadow-none">
                                <div className="card-body text-center">
                                    <img src={diamond} alt="laptop" className="mb-2"/>
                                        <h5 className="h2 mb-1">+ Benefícios</h5>
                                        <p className="fw-medium mb-0">
                                            Nossa assessoria pode<br/>
                                                maximizar os benefícios
                                        </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card border border-label-warning shadow-none">
                                <div className="card-body text-center">
                                    <img src={check} alt="laptop" className="mb-2"/>
                                        <h5 className="h2 mb-1">Suporte</h5>
                                        <p className="fw-medium mb-0">
                                            Orientação e assistência em <br/>
                                                questões relacionadas à manutenção
                                        </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="fatos" className="section-py landing-fun-facts">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-lg-6">
                            <div className="card">
                                <h5 className="card-header">Por que considerar a obtenção do CEBAS Saúde com nossa assessoria?</h5>
                                <div className="card-body">
                                    <p>Experiência Especializada: Nossa equipe possui profissionais com vasta experiência em projetos de investimentos na área da saúde e familiarizados com os processos do InvestSUS. Com nosso conhecimento especializado, podemos orientar sua organização em todas as etapas do processo, desde a identificação de oportunidades até a implementação efetiva do projeto.</p>
                                    <p>Maximização de Oportunidades: O InvestSUS oferece uma variedade de oportunidades de investimento, desde a construção e modernização de unidades de saúde até a implantação de tecnologias inovadoras. Com nossa assessoria, você pode identificar as melhores oportunidades de investimento que estejam alinhadas com os objetivos e capacidades de sua organização.</p>
                                    <p>Gestão Eficiente de Recursos: Investir no setor de saúde requer uma gestão eficiente de recursos financeiros e técnicos. Nossa assessoria pode ajudá-lo a desenvolver um plano de investimento sólido e sustentável, garantindo que seus recursos sejam alocados de forma estratégica para maximizar o impacto e os retornos do investimento.</p>
                                    <p>Conformidade Regulatória: O setor de saúde é altamente regulamentado, e investimentos nesse setor podem envolver uma série de questões legais e regulatórias. Com nossa assessoria, você pode garantir que seu projeto esteja em conformidade com todas as leis e regulamentos aplicáveis, minimizando riscos e evitando possíveis entraves legais.</p>
                                    <p>Parceria Estratégica: Ao escolher nossos serviços de assessoria, você estará estabelecendo uma parceria estratégica com uma equipe comprometida com o sucesso de seu projeto. Estaremos ao seu lado em todas as fases do processo, oferecendo suporte personalizado e soluções sob medida para suas necessidades específicas.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-2"></div>
                        <div className="col-sm-12 col-lg-4">
                            <div className="card-header d-flex justify-content-between">
                                <h5 className="card-title m-0 me-2 pt-1 mb-5 d-flex align-items-center"><IconListDetails />&nbsp;Conjunto de Atividades</h5>
                            </div>
                            <div className="card-body pb-0">
                                <ul className="timeline ms-1 mb-0">
                                    {atividade.map((atv, i) => (
                                        <li className="timeline-item timeline-item-transparent ps-4" key={i}>
                                            <span className={`timeline-point timeline-point-${atv.cor}`}></span>
                                            <div className="timeline-event">
                                                <div className="timeline-header">
                                                    <h6 className="mb-0">{atv.titulo}</h6>
                                                    <small className="text-muted">{atv.servico}</small>
                                                </div>
                                                <p className="mb-0">{atv.descricao}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export const atividade = [
    {
        titulo: "Habilitação e credenciamento",
        descricao: "Envio de documentação e comprovantes",
        servico: "atividade",
        cor: "info",
    },
    {
        titulo: "Indicação de emenda ou programa",
        descricao: "Inclusão da proposta / plano de trabalho",
        servico: "atividade",
        cor: "primary",
    },
    {
        titulo: "Elaboração do plano de trabalho",
        descricao: "Inclusão de documentos e descrição de itens",
        servico: "atividade",
        cor: "warning",
    },
    {
        titulo: "Plano de Trabalho / Proposta em diligência",
        descricao: "Defesa e acompanhamento de diligências",
        servico: "atividade",
        cor: "danger",
    },
    {
        titulo: "Projeto Básico",
        descricao: "Apresentação e defesa de projeto básico",
        servico: "atividade",
        cor: "success",
    },
    {
        titulo: "Conclusão",
        descricao: "Aceite da proposta e aguardo do empenho",
        servico: "atividade",
        cor: "secondary",
    },
    {
        titulo: "Processo de Compras / Licitatório",
        descricao: "Elaboração e inclusão do processo de compras",
        servico: "atividade",
        cor: "primary",
    },
    {
        titulo: "Contratos e pagamentos",
        descricao: "Elaboração e inclusão de contratos e realização de pagamentos OBTV",
        servico: "atividade",
        cor: "warning",
    },
    {
        titulo: "Emissão de Relatórios",
        descricao: "Elaboração e inclusão de relatórios da execução",
        servico: "atividade",
        cor: "danger",
    },
    {
        titulo: "Prestação de Contas",
        descricao: "Envio e acompanhamento da prestação de contas e respostas à diligências",
        servico: "atividade",
        cor: "primary",
    },
    {
        titulo: "Prestação de Contas Concluída",
        descricao: "Aprovação da prestação de contas e envio de mensagem ao Parlamentar",
        servico: "atividade",
        cor: "success",
    },
]