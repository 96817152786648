import React, { useEffect, useState } from "react";
import "../../../assets/vendor/css/pages/page-profile.css"
import axios from "axios";
import { apiURL } from "../../../config/s";
import { formatoDataHora, inicialMaiuscula } from "../../../componentes/Funcoes/BibliotecaFuncoes";
import banner from '../../../assets/img/pages/profile-banner.png'
import { IconAddressBook, IconBuilding, IconCheckbox, IconFlag, IconHomeLink, IconMail, IconPaperclip, IconPhone, IconPin, IconPlaceholder, IconTooltip } from "@tabler/icons-react";
import { Dados } from "../../../componentes/fakeDB";
import { Link } from "react-router-dom";

export default function MeuPerfil() {
    const [usuario, setUsuario] = useState('');

    const fetchUsuario = async (id, token) => {
        try {
            const response = await axios.get(`${apiURL}/usuarios/${id}`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log(response.data.usuario[0])
            setUsuario(response.data.usuario[0]);
        } catch (error) {
            console.error('Erro ao buscar usuário:', error);
        }
    };

    useEffect(() => {
        const id = localStorage.getItem('id');
        const token = localStorage.getItem('_');
        if (id && token) {
            fetchUsuario(id, token);
        } else {
            console.error('ID ou token de autorização ausente no sessionStorage');
        }
    }, []);

    return (
        <>
            <h4 className="py-3 mb-4">
                <span className="text-muted fw-light">Perfil de Usuário /</span> Perfil
            </h4>
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="user-profile-header-banner">
                            <img src={banner} alt="Banner" className="rounded-top" />
                        </div>
                        <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                            <div className="avatar avatar-xl ms-4" style={{ width: '6rem', height: '6rem' }}>
                                <span className="avatar-initial rounded-circle bg-label-primary">{usuario && usuario.nomeCompleto.slice(0, 2)}</span>
                            </div>
                            {usuario && (
                                <div className="flex-grow-1 mt-3 mt-sm-5">
                                    <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                                        <div className="user-profile-info">
                                            <h4>{usuario.nomeCompleto}</h4>
                                            <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                                                <li className="list-inline-item d-flex gap-1">
                                                    <i className="ti ti-color-swatch"></i> {inicialMaiuscula(usuario.role)}
                                                </li>
                                                <li className="list-inline-item d-flex gap-1">
                                                    <i className="ti ti-map-pin"></i> {usuario.email}
                                                </li>
                                                <li className="list-inline-item d-flex gap-1">
                                                    <i className="ti ti-calendar"></i> Juntou-se em {formatoDataHora(usuario.dataCriacao, 'date')}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-5">
                    <div className="card mb-4">
                        <div className="card-body">
                            <small className="card-text text-uppercase">Sobre</small>
                            <ul className="list-unstyled mb-4 mt-3">
                                <li className="d-flex align-items-center mb-3">
                                    <IconBuilding size={"1.25rem"} /> &#160;&#160;&#160; <span>{Dados.nomeEntidade}</span>
                                </li>
                                <li className="d-flex align-items-center mb-3">
                                    <IconCheckbox size={"1.25rem"} /> <span className="fw-medium mx-2 text-heading">CNPJ: </span> <span>{Dados.cnpj}</span>
                                </li>
                                <li className="d-flex align-items-center mb-3">
                                    <IconPaperclip size={"1.25rem"} /> <span className="fw-medium mx-2 text-heading">I. E.: </span> <span>{Dados.ie}</span>
                                </li>
                                <li className="d-flex align-items-center mb-3">
                                    <IconHomeLink size={"1.25rem"} /> <span className="fw-medium mx-2 text-heading">Endereço: </span> <span>{Dados.endereco}</span>
                                </li>
                                <li className="d-flex align-items-center mb-3">
                                    <IconPlaceholder size={"1.25rem"} /> <span className="fw-medium mx-2 text-heading">Complemento: </span> <span>{Dados.complemento}</span>
                                </li>
                                <li className="d-flex align-items-center mb-3">
                                    <IconAddressBook size={"1.25rem"} /> <span className="fw-medium mx-2 text-heading">Bairro: </span> <span>{Dados.bairro}</span>
                                </li>
                                <li className="d-flex align-items-center mb-3">
                                    <IconTooltip size={"1.25rem"} /> <span className="fw-medium mx-2 text-heading">Cep: </span> <span>{Dados.cep}</span>
                                </li>
                                <li className="d-flex align-items-center mb-3">
                                    <IconPin size={"1.25rem"} /> <span className="fw-medium mx-2 text-heading">Município: </span> <span>{Dados.municipio}</span>
                                </li>
                                <li className="d-flex align-items-center mb-3">
                                    <IconFlag size={"1.25rem"} /> <span className="fw-medium mx-2 text-heading">UF: </span> <span>{Dados.uf}</span>
                                </li>
                                <li className="d-flex align-items-center mb-3">
                                    <IconMail size={"1.25rem"} /> <span className="fw-medium mx-2 text-heading">Email: </span> <span>{Dados.email}</span>
                                </li>
                                <li className="d-flex align-items-center mb-3">
                                    <IconPhone size={"1.25rem"} /> <span className="fw-medium mx-2 text-heading">Telefone: </span> <span>{Dados.telefone}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-xl-8 col-lg-7 col-md-7">
                    <div className="card mb-4">
                        <div className="card-header">
                            <h5 className="card-action-title mb-0">
                                Dados complementares
                            </h5>
                        </div>
                        <div className="card-body">
                            <div>
                                <p className="card-text">Configuração Global</p>
                                <ul className="list-unstyled mb-4 mt-3">
                                    <li className="d-flex align-items-center mb-3">
                                        <span className="fw-medium mx-2 text-heading">Email Processo: </span> <span>{Dados.emailProcesso}</span>
                                    </li>
                                    <li className="d-flex align-items-center mb-3">
                                        <span className="fw-medium mx-2 text-heading">Telefone Processo: </span> <span>{Dados.telefoneProcesso}</span>
                                    </li>
                                </ul>
                            </div>
                            <hr />
                            <p className="card-text">Redefinir Senha</p>
                            <Link to={'/esqueceu-senha'} className="btn btn-primary waves-effect waves-light" >Redefinir Senha</Link>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}