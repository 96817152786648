import React, { useState } from "react";
import bgCTA from "../../../assets/img/front-pages/backgrounds/cta-bg-light.png";
import dashboard from "../../../assets/img/front-pages/landing-page/cta-dashboard.png";
import { IconBrandWhatsapp, IconMail, IconPin } from "@tabler/icons-react";
import Telefone from "./Telefone";
import axios from "axios";
import { apiURL } from "../../../config/s";

const BlockUI = () => {
    const overlayStyle = {
        zIndex: 1000,
        border: 'none',
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: 'rgb(255, 255, 255)',
        opacity: 0.553073,
        cursor: 'default',
        position: 'absolute'
    };

    const spinner = {
        zIndex: 9000,
        border: 'none',
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        cursor: 'default',
        position: 'absolute'
    };

    return (
        <>
            <div className="blockUI blockOverlay" style={overlayStyle}></div>
            <div className="row justify-content-center py-5" style={spinner}>
                <div className="col-xl-6 mb-4 mb-xl-0">
                    <center>
                        <div className="spinner-border spinner-border-lg text-primary" role="status">
                            <span className="visually-hidden text-center">Carregando...</span>
                        </div>
                    </center>
                </div>
            </div>
        </>
    );
};

export default function Contato() {
    const [carregando, setCarregando] = useState(false);
    const [mensagem, setMensagem] = useState('');
    const [error, setError] = useState('');

    const [formValues, setFormValues] = useState({
        nomeCompleto: '',
        email: '',
        nomeEmpresa: '',
        telefone: '',
        mensagem: ''
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleTelefone = (telefone) => {
        setFormValues({
            ...formValues,
            telefone: telefone
        });
    };

    const validateForm = () => {
        const form = document.getElementById("formularioContato");
        if (!form.checkValidity()) {
            form.classList.add('was-validated');
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            try {
                setCarregando(true);
                const response = await axios.post(`${apiURL}/contato/formulario`, formValues);
                setMensagem(response.data.mensagem);
                setFormValues({
                    nomeCompleto: '',
                    email: '',
                    nomeEmpresa: '',
                    telefone: '',
                    mensagem: ''
                });
            } catch (error) {
                setError(error.message);
                console.error(error);
            } finally {
                setCarregando(false);
            }
        }
    };

    return (
        <div data-bs-spy="scroll" className="scrollspy-example">

            <section id="hero-animation">
                <div id="landingHero" className="section-py landing-hero position-relative" style={{ paddingTop: '200px' }}>
                    <div className="container">
                        <div className="hero-text-box text-center">
                            <h1 className="text-primary display-6 fw-bold">Contato</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section id="oQueFazemos" style={{ marginTop: '143px' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card">
                                <h3 className="card-header bg bg-primary text-white">Contato</h3>
                                <div className="card-body mt-5">
                                    <div className="d-flex align-content-between justify-content-start mb-3">
                                        <IconPin size={16} />&nbsp;<span className="fw-bold">Endereço:</span>&nbsp;Ribeirão Preto/SP
                                    </div>
                                    <div className="d-flex align-content-between justify-content-start mb-3">
                                        <IconBrandWhatsapp size={16} />&nbsp;<span className="fw-bold">WhatsApp:</span>&nbsp;(16) 99294-6842
                                    </div>
                                    <div className="d-flex align-content-between justify-content-start mb-3">
                                        <IconMail size={16} />&nbsp;<span className="fw-bold">E-mail:</span>&nbsp;info@institutometricas.org.br
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="card">
                                <h3 className="card-header bg bg-primary text-white">Formulário</h3>
                                <div className="card-body">
                                    {carregando && <BlockUI />}
                                    <form className="mt-4" id="formularioContato" onSubmit={handleSubmit}>
                                        <div className="row g-3">
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="nomeCompleto">Nome Completo</label>
                                                <input 
                                                    type="text" 
                                                    id="nomeCompleto" 
                                                    name="nomeCompleto" 
                                                    className="form-control" 
                                                    placeholder="João da Silva" 
                                                    value={formValues.nomeCompleto}
                                                    onChange={handleInputChange} 
                                                    required 
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="email">E-mail</label>
                                                <input 
                                                    type="email" 
                                                    id="email" 
                                                    name="email" 
                                                    className="form-control" 
                                                    placeholder="joaodasilva@hotmail.com" 
                                                    value={formValues.email}
                                                    onChange={handleInputChange} 
                                                    required 
                                                />
                                            </div>
                                        </div>
                                        <div className="row g-3 mt-4">
                                            <div className="col-md-6">
                                                <label className="form-label" htmlFor="nomeEmpresa">Nome da Empresa</label>
                                                <input 
                                                    type="text" 
                                                    id="nomeEmpresa" 
                                                    name="nomeEmpresa" 
                                                    className="form-control" 
                                                    placeholder="Santa Casa de São Paulo" 
                                                    value={formValues.nomeEmpresa}
                                                    onChange={handleInputChange} 
                                                    required 
                                                />
                                            </div>
                                            <div className="invalid-feedback">Por favor, preencha este campo.</div>
                                            <Telefone onInputChange={handleTelefone} />
                                        </div>
                                        <div className="row g-3 mt-4">
                                            <div className="col-md-12">
                                                <label className="form-label" htmlFor="mensagem">Escreva sua mensagem</label>
                                                <textarea 
                                                    id="mensagem" 
                                                    name="mensagem" 
                                                    className="form-control" 
                                                    placeholder="Sua mensagem" 
                                                    value={formValues.mensagem}
                                                    onChange={handleInputChange} 
                                                    required 
                                                />
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary mt-4">Enviar</button>
                                    </form>
                                    {mensagem && (
                                        <div className="alert alert-success mt-4" role="alert">{mensagem}</div>
                                    )}

                                    {error && (
                                        <div className="alert alert-danger mt-4" role="alert">{error}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="qualidades" style={{ marginTop: '143px' }}>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-6">
                            <div className="card border-1 border-label-primary mb-3">
                                <div className="row g-0">
                                    <div className="col-md-3">
                                        <img className="card-img card-img-left" src={"https://images.pexels.com/photos/236380/pexels-photo-236380.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"} alt="Card " style={{ objectFit: 'cover', maxHeight: '213px', height: '100%' }} />
                                    </div>
                                    <div className="col-md-9">
                                        <div className="card-body">
                                            <h5 className="card-title">Atuação na Saúde</h5>
                                            <p className="card-text">Destacamos nossa atuação na área da saúde, onde possuímos vasta experiência no desenvolvimento de credenciamento e habilitação de novos serviços, além da gestão de convênios.</p>
                                            <p className="card-text">Garantimos a validação e autenticação eficiente dos projetos, fortalecendo a confiança e a transparência em todas as nossas ações.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="card border-1 border-label-primary mb-3">
                                <div className="row g-0">
                                    <div className="col-md-9">
                                        <div className="card-body">
                                            <h5 className="card-title">Relações Governamentais e Institucionais</h5>
                                            <p className="card-text">Atuamos fortemente em advocacy e mobilização para captação de recursos públicos e formação de parcerias público-privadas.</p>
                                            <p className="card-text">Através de nossas estratégias de advocacy, adaptamos às políticas públicas e decisões governamentais, assegurando que os interesses de nossos parceiros sejam representados de maneira eficaz.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <img className="card-img card-img-right" src={"https://images.pexels.com/photos/13640268/pexels-photo-13640268.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"} alt="Card " style={{ objectFit: 'cover', maxHeight: '213px', height: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="landingCTA" className="section-py landing-cta position-relative p-lg-0 pb-0" style={{ marginTop: '143px' }}>
                <img src={bgCTA} className="position-absolute bottom-0 end-0 scaleX-n1-rtl h-100 w-100 z-n1" alt="cta" />
                <div className="container">
                    <div className="row align-items-center gy-5 gy-lg-0">
                        <div className="col-lg-6 text-center text-lg-start">
                            <h6 className="h2 text-primary fw-bold mb-1">Pronto para Iniciar?</h6>
                            <p className="fw-medium mb-4">Dê o start que você precisa em seus projetos com nosso gerenciador de projetos</p>
                            <a href="payment-page.html" className="btn btn-lg btn-primary waves-effect waves-light">Solicitar Demonstração</a>
                        </div>
                        <div className="col-lg-6 pt-lg-5 text-center text-lg-end">
                            <img src={dashboard} alt="cta dashboard" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
