import React, { useEffect } from "react";
import WebsiteMenu from "../componentes/Menu/WebsiteMenu";

import "../assets/vendor/css/pages/front-page-landing.css"
import "../assets/vendor/css/pages/front-page.css"
import "../assets/vendor/libs/swiper/swiper.css"
import "../assets/vendor/libs/nouislider/nouislider.css"
import Footer from "../componentes/Footer";
import ScrollToTopButton from "../componentes/ScrollToTopBotao";
import LGPD from "../componentes/LGPD";

export default function BaseWebsite({ children }) {

    useEffect(() => {
        // Função para alterar as classes da tag <html>
        const changeHtmlClass = (newClass) => {
          document.documentElement.className = newClass;
        };
    
        // Definindo a nova classe
        const newClass = 'light-style layout-navbar-fixed layout-wide';
        changeHtmlClass(newClass);
    
        // Limpeza: restaurar a classe original quando o componente for desmontado
        return () => {
          document.documentElement.className = '';
        };
      }, []); 

    return (
        <div>
            <LGPD/>
            <WebsiteMenu />
            {children}
            <Footer />
            <ScrollToTopButton/>
        </div>
    );
}
