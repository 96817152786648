import axios from "axios";
import React, { useState } from "react";
import { apiURL } from "../../../config/s";

const BlockUI = () => {
    const overlayStyle = {
        zIndex: 1000,
        border: 'none',
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: 'rgb(255, 255, 255)',
        opacity: 0.553073,
        cursor: 'default',
        position: 'absolute'
    };

    const spinner = {
        zIndex: 9000,
        border: 'none',
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        cursor: 'default',
        position: 'absolute'
    };

    return (
        <>
            <div className="blockUI blockOverlay" style={overlayStyle}></div>
            <div className="row justify-content-center py-5" style={spinner}>
                <div className="col-xl-6 mb-4 mb-xl-0">
                    <center>
                        <div className="spinner-border spinner-border-lg text-primary" role="status">
                            <span className="visually-hidden text-center">Carregando...</span>
                        </div>
                    </center>
                </div>
            </div>
        </>
    );
};

export default function Inscricao() {
    const [carregando, setCarregando] = useState(false);
    const [mensagem, setMensagem] = useState('');
    const [error, setError] = useState('');

    const [formValues, setFormValues] = useState({
        email: '',
    });

    const validateForm = () => {
        const form = document.getElementById("inscricao");
        if (!form.checkValidity()) {
            form.classList.add('was-validated');
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validateForm()) {
            try {
                setCarregando(true);
                const response = await axios.post(`${apiURL}/contato/inscricao`, formValues);
                setMensagem(response.data.mensagem);
                setFormValues({
                    email: '',
                });
            } catch (error) {
                setError(error.message);
                console.error(error);
            } finally {
                setCarregando(false);
            }
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    return (
        <form className="footer-form" id="inscricao" onSubmit={handleSubmit}>
            {carregando && <BlockUI />}
            <label htmlFor="footer-email" className="small">Receba nossas últimas informações</label>
            <div className="d-flex mt-1">
                <input
                    type="email"
                    className="form-control rounded-0 rounded-start-bottom rounded-start-top"
                    id="footer-email"
                    name="email"
                    placeholder="Seu e-mail"
                    value={formValues.email}
                    onChange={handleInputChange}
                    required
                />
                <button
                    type="submit"
                    className="btn btn-primary shadow-none rounded-0 rounded-end-bottom rounded-end-top waves-effect waves-light"
                >
                    Inscrever
                </button>
            </div>
            <small htmlFor="footer-email" className="small">Não se preocupe, nós não enviamos spam!</small>

            {mensagem && (
                <p className="alert alert-success mt-2" role="alert">{mensagem}</p>
            )}

            {error && (
                <p className="alert alert-danger mt-2" role="alert">{error}</p>
            )}
        </form>
    );
}
