import React from 'react';

const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 197.31 68"
      style={{width: '132px', height: '44px'}}
    >
      <path
        d="M68.34,34a34,34,0,1,1-34-34,34,34,0,0,1,34,34"
        transform="translate(-0.34)"
        style={{ fill: '#7367f0' }}
      />
      <path
        d="M42.69,17.72H36.92l2.08,9c.35,1.52,2,2.88,3.47,2.53a2.81,2.81,0,0,1,3.37,2.11l4.37,18.89H56L51.33,30.15A2.81,2.81,0,0,0,48,28a2.81,2.81,0,0,1-3.38-2.11Z"
        transform="translate(-0.34)"
        style={{ fill: '#fff' }}
      />
      <path
        d="M30.64,17.72H24.87l2.19,9.48a2.81,2.81,0,0,0,3.37,2.11,2.81,2.81,0,0,1,3.37,2.1l3.08,13.33h5.77L39.28,30.15A2.81,2.81,0,0,0,35.91,28a2.81,2.81,0,0,1-3.37-2.11Z"
        transform="translate(-0.34)"
        style={{ fill: '#fff' }}
      />
      <path
        d="M18.48,17.72H12.71L14.9,27.2a2.81,2.81,0,0,0,3.37,2.11,2.8,2.8,0,0,1,3.37,2.1L26,50.28h5.77L27.12,30.15A2.81,2.81,0,0,0,23.75,28a2.81,2.81,0,0,1-3.37-2.11Z"
        transform="translate(-0.34)"
        style={{ fill: '#fff' }}
      />
      <path d="M97.47,43.09a7.05,7.05,0,0,1,3.28.72A5,5,0,0,1,102.92,46a7.79,7.79,0,0,1,.78,3.69v9h-2.09V49.9a5.19,5.19,0,0,0-1.16-3.68A4.21,4.21,0,0,0,97.18,45a5.42,5.42,0,0,0-2.74.66,4.44,4.44,0,0,0-1.8,1.91,6.6,6.6,0,0,0-.63,3v8.1H89.93V49.9a5.19,5.19,0,0,0-1.17-3.68A4.28,4.28,0,0,0,85.47,45a5.46,5.46,0,0,0-2.73.66,4.4,4.4,0,0,0-1.81,1.91,6.6,6.6,0,0,0-.63,3v8.1H78.22V43.24h2V47.4l-.32-.73A5.55,5.55,0,0,1,82.17,44a7,7,0,0,1,3.71-.95,6.44,6.44,0,0,1,3.84,1.13,5.25,5.25,0,0,1,2.06,3.39L91,47.29a6,6,0,0,1,2.39-3,7.2,7.2,0,0,1,4.12-1.16" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M115.17,58.79a8.47,8.47,0,0,1-4.22-1A7.22,7.22,0,0,1,108.1,55a7.91,7.91,0,0,1-1-4.06,8.1,8.1,0,0,1,1-4,7.21,7.21,0,0,1,2.7-2.78,7.49,7.49,0,0,1,3.86-1,7.61,7.61,0,0,1,3.86,1,7,7,0,0,1,2.65,2.76,8.39,8.39,0,0,1,1,4.1c0,.09,0,.2,0,.3s0,.23,0,.34H108.66V50H121l-.83.61a6.19,6.19,0,0,0-.71-3,5.34,5.34,0,0,0-2-2,5.44,5.44,0,0,0-2.84-.73,5.55,5.55,0,0,0-2.85.73,5.14,5.14,0,0,0-2,2.06,6.32,6.32,0,0,0-.7,3V51a6,6,0,0,0,.78,3.1,5.39,5.39,0,0,0,2.17,2.09,6.72,6.72,0,0,0,3.18.74,6.91,6.91,0,0,0,2.63-.49,5.64,5.64,0,0,0,2.1-1.53l1.17,1.35a6.58,6.58,0,0,1-2.57,1.88,8.64,8.64,0,0,1-3.39.64" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>      
      <path d="M123.22,43.24h9.54V45h-9.54Zm7.28,15.55A4.08,4.08,0,0,1,126,54.3V39.83h2.08V54.18a2.93,2.93,0,0,0,.69,2.09,2.58,2.58,0,0,0,2,.73,3.36,3.36,0,0,0,2.29-.79l.73,1.5a3.88,3.88,0,0,1-1.48.82,5.93,5.93,0,0,1-1.77.26" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M136.81,58.65V43.24h2v4.19l-.2-.73A5.1,5.1,0,0,1,140.77,44a7.26,7.26,0,0,1,3.79-.92v2h-.47a5,5,0,0,0-3.82,1.48,6,6,0,0,0-1.38,4.21v7.87Z" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M148.67,39.83a1.48,1.48,0,0,1-1.09-.44,1.46,1.46,0,0,1-.44-1.06,1.41,1.41,0,0,1,.44-1,1.5,1.5,0,0,1,1.09-.42,1.49,1.49,0,0,1,1.08.41,1.31,1.31,0,0,1,.44,1,1.5,1.5,0,0,1-.42,1.09,1.48,1.48,0,0,1-1.1.44m-1.06,3.41h2.08V58.65h-2.08Z" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M161.17,58.79a8.2,8.2,0,0,1-4.1-1A7.36,7.36,0,0,1,154.24,55a8.56,8.56,0,0,1,0-8.14,7.29,7.29,0,0,1,2.83-2.76,8.3,8.3,0,0,1,4.1-1,8.07,8.07,0,0,1,3.55.76,5.76,5.76,0,0,1,2.5,2.29l-1.56,1.06a4.86,4.86,0,0,0-2-1.73,6.16,6.16,0,0,0-5.51.19,5.41,5.41,0,0,0-2.09,2.1,7,7,0,0,0,0,6.34,5.41,5.41,0,0,0,2.09,2.1,6,6,0,0,0,3,.74,5.87,5.87,0,0,0,2.55-.55,5,5,0,0,0,2-1.71l1.56,1.06a5.92,5.92,0,0,1-2.5,2.28,8.07,8.07,0,0,1-3.55.77" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M174.91,58.79a7.32,7.32,0,0,1-3-.57,4.48,4.48,0,0,1-2-1.58,4.15,4.15,0,0,1-.68-2.34,4.25,4.25,0,0,1,.57-2.18,4,4,0,0,1,1.88-1.59,8.52,8.52,0,0,1,3.51-.6h5.31v1.55h-5.25a4.62,4.62,0,0,0-3.1.8,2.69,2.69,0,0,0,.16,4.08,4.63,4.63,0,0,0,2.88.79,5.57,5.57,0,0,0,3-.81A4.62,4.62,0,0,0,180.13,54l.47,1.44a4.87,4.87,0,0,1-2,2.43,6.75,6.75,0,0,1-3.65.91m5.31-.14V55.24l-.09-.56V49a4,4,0,0,0-1.1-3,4.52,4.52,0,0,0-3.27-1.06,8.22,8.22,0,0,0-2.85.5,7.21,7.21,0,0,0-2.29,1.32l-.94-1.56a8.33,8.33,0,0,1,2.82-1.54,11,11,0,0,1,3.46-.54,6.64,6.64,0,0,1,4.63,1.48,5.82,5.82,0,0,1,1.63,4.51v9.57Z" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M191.29,58.79a11.81,11.81,0,0,1-3.61-.54A7.89,7.89,0,0,1,185,56.89l.94-1.65a8.88,8.88,0,0,0,2.41,1.22,9.34,9.34,0,0,0,3.08.51,5.58,5.58,0,0,0,3.17-.69,2.13,2.13,0,0,0,1-1.83,1.72,1.72,0,0,0-.54-1.34,3.64,3.64,0,0,0-1.43-.73,17,17,0,0,0-2-.43c-.73-.11-1.44-.25-2.16-.41a7.5,7.5,0,0,1-1.95-.69,3.91,3.91,0,0,1-1.43-1.24,3.72,3.72,0,0,1-.54-2.12,3.84,3.84,0,0,1,.71-2.26,4.68,4.68,0,0,1,2.07-1.57,8.64,8.64,0,0,1,3.33-.57,11.38,11.38,0,0,1,3,.4,8.2,8.2,0,0,1,2.47,1l-.91,1.67a7,7,0,0,0-2.2-1,9.09,9.09,0,0,0-2.35-.31,5.24,5.24,0,0,0-3.07.72,2.17,2.17,0,0,0-1,1.83,1.78,1.78,0,0,0,.54,1.38,3.54,3.54,0,0,0,1.44.78,14.48,14.48,0,0,0,2,.46c.72.11,1.43.26,2.15.42a9.42,9.42,0,0,1,2,.68,3.53,3.53,0,0,1,1.44,1.2,3.44,3.44,0,0,1,.55,2,3.72,3.72,0,0,1-.75,2.33,4.77,4.77,0,0,1-2.18,1.53,9.67,9.67,0,0,1-3.44.54" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/><rect x="109.47" y="36.87" width="9.54" height="1.73" style={{ fill: '#4b465c' }}/>
      <path d="M79.2,12a1.51,1.51,0,0,1-1.09-.44,1.47,1.47,0,0,1-.44-1.06,1.44,1.44,0,0,1,.44-1A1.54,1.54,0,0,1,79.2,9a1.5,1.5,0,0,1,1.09.42,1.33,1.33,0,0,1,.45,1,1.54,1.54,0,0,1-.43,1.09A1.51,1.51,0,0,1,79.2,12m-1.06,3.42h2.1V30.91h-2.1Z" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M94,15.29a7.24,7.24,0,0,1,3.31.72,5.22,5.22,0,0,1,2.24,2.2,7.63,7.63,0,0,1,.81,3.71v9H98.24V22.13A5.13,5.13,0,0,0,97,18.43a4.6,4.6,0,0,0-3.43-1.25,6,6,0,0,0-2.88.66,4.52,4.52,0,0,0-1.88,1.92,6.27,6.27,0,0,0-.67,3v8.13H86.07V15.44h2v4.24l-.32-.79a5.64,5.64,0,0,1,2.36-2.64,7.44,7.44,0,0,1,3.86-1" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M110.15,31.06a12,12,0,0,1-3.63-.54,7.89,7.89,0,0,1-2.68-1.37l1-1.65a9,9,0,0,0,2.41,1.22,9.42,9.42,0,0,0,3.1.51,5.64,5.64,0,0,0,3.18-.69,2.14,2.14,0,0,0,1-1.84,1.7,1.7,0,0,0-.54-1.34,3.59,3.59,0,0,0-1.43-.74,17.39,17.39,0,0,0-2-.43c-.72-.11-1.45-.25-2.16-.41a8.24,8.24,0,0,1-2-.69A3.85,3.85,0,0,1,105,21.84a3.66,3.66,0,0,1-.55-2.13,3.87,3.87,0,0,1,.71-2.27,4.66,4.66,0,0,1,2.08-1.57,8.5,8.5,0,0,1,3.34-.58,12,12,0,0,1,3,.4,8.34,8.34,0,0,1,2.47,1l-.91,1.68a7.16,7.16,0,0,0-2.21-1,9.11,9.11,0,0,0-2.36-.31,5.28,5.28,0,0,0-3.08.72,2.19,2.19,0,0,0-1,1.85A1.79,1.79,0,0,0,107,21a3.74,3.74,0,0,0,1.44.78,14.44,14.44,0,0,0,2,.46c.72.12,1.43.26,2.15.43a9.6,9.6,0,0,1,2,.67A3.7,3.7,0,0,1,116,24.59a3.55,3.55,0,0,1,.55,2.05,3.75,3.75,0,0,1-.76,2.34,4.81,4.81,0,0,1-2.18,1.54,9.78,9.78,0,0,1-3.46.54" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M118,15.44h9.58v1.74H118Zm7.31,15.62a4.54,4.54,0,0,1-3.36-1.18,4.47,4.47,0,0,1-1.18-3.33V12h2.09V26.43a2.91,2.91,0,0,0,.7,2.1,2.59,2.59,0,0,0,2,.73,3.36,3.36,0,0,0,2.29-.79l.74,1.5a3.94,3.94,0,0,1-1.49.83,6.28,6.28,0,0,1-1.78.26" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M133.4,12a1.51,1.51,0,0,1-1.09-.44,1.47,1.47,0,0,1-.44-1.06,1.44,1.44,0,0,1,.44-1A1.54,1.54,0,0,1,133.4,9a1.5,1.5,0,0,1,1.09.42,1.33,1.33,0,0,1,.45,1,1.54,1.54,0,0,1-.43,1.09,1.49,1.49,0,0,1-1.11.44m-1.06,3.42h2.1V30.91h-2.1Z" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M137.85,15.44h9.58v1.74h-9.58Zm7.31,15.62a4.54,4.54,0,0,1-3.36-1.18,4.51,4.51,0,0,1-1.18-3.33V12h2.1V26.43a3,3,0,0,0,.69,2.1,2.59,2.59,0,0,0,2,.73,3.38,3.38,0,0,0,2.3-.79l.74,1.5a4,4,0,0,1-1.49.83,6.35,6.35,0,0,1-1.79.26" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M158.63,31.06a7.55,7.55,0,0,1-3.45-.74,5.18,5.18,0,0,1-2.28-2.21,7.56,7.56,0,0,1-.81-3.68v-9h2.09v8.75a5.11,5.11,0,0,0,1.23,3.73,4.55,4.55,0,0,0,3.43,1.26,5.7,5.7,0,0,0,2.81-.67,4.33,4.33,0,0,0,1.83-1.93,6.74,6.74,0,0,0,.63-3V15.44h2.1V30.91h-2V26.67l.33.77a5.67,5.67,0,0,1-2.29,2.66,6.69,6.69,0,0,1-3.61,1" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M169.63,15.44h9.58v1.74h-9.58Zm7.31,15.62a4.51,4.51,0,0,1-3.36-1.18,4.47,4.47,0,0,1-1.18-3.33V12h2.09V26.43a3,3,0,0,0,.69,2.1,2.61,2.61,0,0,0,2,.73,3.38,3.38,0,0,0,2.3-.79l.74,1.5a4,4,0,0,1-1.49.83,6.34,6.34,0,0,1-1.78.26" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      <path d="M189.76,31.06a8,8,0,0,1-4-1,7.41,7.41,0,0,1-2.81-2.8,7.93,7.93,0,0,1-1-4.08,7.92,7.92,0,0,1,1-4.09,7.37,7.37,0,0,1,2.8-2.77,8,8,0,0,1,4-1,8.17,8.17,0,0,1,4.08,1,7.24,7.24,0,0,1,2.8,2.77,8,8,0,0,1,1,4.09,8,8,0,0,1-1,4.08,7.3,7.3,0,0,1-2.82,2.8,8.05,8.05,0,0,1-4.06,1m0-1.86a5.8,5.8,0,0,0,3-.75,5.36,5.36,0,0,0,2.05-2.12,6.49,6.49,0,0,0,.75-3.17,6.45,6.45,0,0,0-.75-3.18,5.4,5.4,0,0,0-2.05-2.11,6.16,6.16,0,0,0-5.89,0A5.5,5.5,0,0,0,184.78,20a6.24,6.24,0,0,0-.77,3.18,6.28,6.28,0,0,0,.77,3.17,5.46,5.46,0,0,0,2.06,2.12,5.72,5.72,0,0,0,2.92.75" transform="translate(-0.34)" style={{ fill: '#4b465c' }}/>
      {/* Add other paths similarly */}
    </svg>
  );
};

export default Logo;
