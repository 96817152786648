import React from "react";

import laptop from "../../../../assets/img/front-pages/icons/laptop.png";
import success from "../../../../assets/img/front-pages/icons/user-success.png";
import diamond from "../../../../assets/img/front-pages/icons/diamond-info.png";
import check from "../../../../assets/img/front-pages/icons/check-warning.png";

export default function Cebas() {

    return (
        <div data-bs-spy="scroll" className="scrollspy-example">

            <section id="hero-animation">
                <div id="landingHero" className="section-py landing-hero position-relative" style={{ paddingTop: '200px' }}>
                    <div className="container">
                        <div className="hero-text-box text-center">
                            <h1 className="text-primary display-6 fw-bold">CEBAS Saúde</h1>
                            <h2 className="hero-sub-title h6 mb-4 pb-1">
                                Você sabia que o Certificado de Entidade Beneficente de Assistência Social na Área da Saúde (CEBAS Saúde) pode trazer uma série de benefícios?
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <section id="qualidades" style={{ marginTop: '143px' }}>
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-8">
                            <div className="card border-1 border-label-primary mb-3">
                                <div className="row g-0">
                                    <div className="col-md-3">
                                        <img className="card-img card-img-left" src={"https://www.cofen.gov.br/wp-content/uploads/2023/03/SUS-LZ988494002CN.jpg"} alt="Card " style={{ objectFit: 'cover', maxHeight: '213px', height: '100%' }} />
                                    </div>
                                    <div className="col-md-9">
                                        <div className="card-body">
                                            <h5 className="card-title">Benefícios</h5>
                                            <p className="card-text">O CEBAS Saúde é um reconhecimento concedido pelo Governo Federal a entidades privadas sem fins lucrativos que prestam serviços na área da saúde, de acordo com a Lei nº 12.101/2009. Ter esse certificado pode abrir portas para diversas oportunidades e facilitar o acesso a recursos e incentivos fiscais.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-body d-flex align-items-center justify-content-between">
                                    <img className="card-img-top" src={"https://www.gov.br/saude/pt-br/composicao/saes/dcebas/logo-cebas.jpg"} alt="Transferegov logo" style={{ height: '126px', width: '126px' }} />
                                    <p className="card-text">Departamento de Certificação de Entidades Beneficentes de Assistência Social em Saúde</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section id="fatos" className="section-py landing-fun-facts">
                <div className="container">
                    <div className="row gy-3">
                        <div className="col-sm-6 col-lg-3">
                            <div className="card border border-label-primary shadow-none">
                                <div className="card-body text-center">
                                    <img src={laptop} alt="laptop" className="mb-2" />
                                    <h5 className="h2 mb-1">Economia </h5>
                                    <p className="fw-medium mb-0">
                                        Conosco você pode<br />
                                        economizar tempo e recurso
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card border border-label-success shadow-none">
                                <div className="card-body text-center">
                                    <img src={success} alt="laptop" className="mb-2" />
                                    <h5 className="h2 mb-1">Experiência</h5>
                                    <p className="fw-medium mb-0">
                                        Equipe de especialistas<br />
                                        em credenciamento e habilitação
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card border border-label-info shadow-none">
                                <div className="card-body text-center">
                                    <img src={diamond} alt="laptop" className="mb-2" />
                                    <h5 className="h2 mb-1">+ Benefícios</h5>
                                    <p className="fw-medium mb-0">
                                        Nossa assessoria pode<br />
                                        maximizar os benefícios
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="card border border-label-warning shadow-none">
                                <div className="card-body text-center">
                                    <img src={check} alt="laptop" className="mb-2" />
                                    <h5 className="h2 mb-1">Suporte</h5>
                                    <p className="fw-medium mb-0">
                                        Orientação e assistência em <br />
                                        questões relacionadas à manutenção
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="fatos" className="section-py landing-fun-facts">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 col-lg-3"></div>
                        <div className="col-sm-6 col-lg-6">
                            <div className="card">
                                <h5 className="card-header">Por que considerar a obtenção do CEBAS Saúde com nossa assessoria?</h5>
                                <div className="card-body">
                                    <p>Experiência Especializada: Contamos com uma equipe experiente e especializada em processos de certificação e legislação relacionada à saúde, garantindo que sua organização esteja em conformidade com os requisitos necessários.</p>
                                    <p>Economia de Tempo e Recursos: O processo de obtenção do CEBAS Saúde pode ser complexo e demorado. Com nossa assessoria, você pode economizar tempo e recursos, pois cuidaremos de todos os trâmites burocráticos, permitindo que você se concentre em suas atividades principais.</p>
                                    <p>Maximização de Benefícios: Além de ajudar na obtenção do certificado, nossa assessoria também pode orientá-lo sobre como maximizar os benefícios associados ao CEBAS Saúde, como acesso a financiamentos, isenções fiscais e outras vantagens que podem impulsionar o impacto e a sustentabilidade de sua organização.</p>
                                    <p>Suporte Contínuo: Nosso compromisso não termina com a obtenção do certificado. Estamos aqui para oferecer suporte contínuo, fornecendo orientação e assistência em questões relacionadas à manutenção do CEBAS Saúde e qualquer atualização na legislação pertinente.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 col-lg-3"></div>
                    </div>
                </div>
            </section>




        </div>
    )
}