import React from "react";
import "../../../assets/vendor/css/pages/page-misc.css";

export default function PaginaNaoEncontrada() {
    return (
        <>
            <div className="container-xxl container-p-y">
                <div className="misc-wrapper">
                    <h2 className="mb-1 mx-2">Ops! Página não encontrada 😖</h2>
                    <p className="mb-4 mx-2">A página que você procura não exite.</p>
                    <a href="/" className="btn btn-primary mb-4 waves-effect waves-light">Voltar</a>
                    <div className="mt-4">
                        <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/page-misc-error.png" alt="page-misc-not-authorized" width="170" className="img-fluid"/>
                    </div>
                </div>
            </div>
            <div className="container-fluid misc-bg-wrapper">
                <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/bg-shape-image-light.png" alt="page-misc-not-authorized" data-app-light-img="illustrations/bg-shape-image-light.png" data-app-dark-img="illustrations/bg-shape-image-dark.png"/>
            </div>
        </>
    )
}