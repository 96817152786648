import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';

const Telefone = ({ onInputChange }) => {
    const [value, setValue] = useState('');
    const [mask, setMask] = useState('(99) 99999-9999');

    useEffect(() => {
        if (value.length === 11) {
            setMask('(99) 9999-99999');
        }
    }, [value]);

    const handleChange = (event) => {
        const inputValue = event.target.value.replace(/\D/g, '');
        setValue(inputValue);
        onInputChange(inputValue);
    };

    return (
        <div className="col-md-6">
            <label className="form-label" htmlFor="telefone">Telefone</label>
            <InputMask
                mask={mask}
                value={value}
                onChange={handleChange}
                required
            >
                {(inputProps) => (
                    <input
                        {...inputProps}
                        type="text"
                        id="telefone"
                        name="telefone"
                        className="form-control"
                        placeholder="(00) 00000-0000"
                    />
                )}
            </InputMask>
        </div>
    );
};

export default Telefone;
