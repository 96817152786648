import React, { useEffect, useState } from "react";

import elemento from "../../../assets/img/elements/9.jpg"
import axios from "axios";
import { apiURL } from "../../../config/s";
import { formatoDataHora, limitar30Palavras } from "../../../componentes/Funcoes/BibliotecaFuncoes";
import { Link, useLocation } from "react-router-dom";
import { IconCalendar } from "@tabler/icons-react";


const BlockUI = () => {
    const overlayStyle = {
        zIndex: 1000,
        border: 'none',
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: 'rgb(255, 255, 255)',
        opacity: 1,
        cursor: 'default',
        position: 'absolute',
        display: 'block'
    };

    const spinner = {
        zIndex: 9000,
        border: 'none',
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        cursor: 'default',
        position: 'absolute'
    };

    return (
        <>
            <div className="blockUI blockOverlay" style={overlayStyle}></div>
            <div className="row" style={spinner}>
                <div className="col-xl-12 d-flex align-items-center justify-content-center">
                    <div className="spinner-border spinner-border-lg text-primary" role="status">
                        <span className="visually-hidden">Carregando...</span>
                    </div>
                </div>
            </div>

        </>
    );
};

export default function Blog() {

    const [artigos, setArtigos] = useState([]);
    const location = useLocation();
    const [carregando, setCarregando] = useState(false);
    const [nuvemTags, setNuvemTags] = useState([]);

    const fetchPosts = async () => {
        try {
            setCarregando(true);
            const response = await axios.get(`${apiURL}/artigos`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setArtigos(response.data.resultado);

            try {
                const nuvem = await axios.get(`${apiURL}/artigos/tags/volume`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setNuvemTags(nuvem.data.resultado);
            } catch (error) {
                console.error(error)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setCarregando(false);
        }
    }
    useEffect(() => {
        fetchPosts();
    }, []);
    return (
        <div data-bs-spy="scroll" className="scrollspy-example">
            <section id="hero-animation">
                <div id="landingHero" className="section-py landing-hero position-relative">
                    <div className="container">
                        <div className="hero-text-box text-center">
                            <h1 className="text-primary display-6 fw-bold mt-4 pt-4">Blog</h1>
                            <h2 className="hero-sub-title h6 mb-4 pb-1">
                                Comprometidos com a construção de projetos<br className="d-none d-lg-block" />
                                de saúde pública que façam a diferença.
                            </h2>
                        </div>

                    </div>
                </div>
            </section>

            <section id="heroBlog" className="section-py landing-features">
                {carregando && <BlockUI />}
                <div className="container">
                    <div className="row">
                        {artigos && artigos.length > 0 && (
                            <div className="row">
                                <div className="col-lg-5">
                                    <Link to={`${location.pathname}/${artigos[0].urlArtigo}`}>
                                        <div class="card bg-dark border-0 text-white">
                                            <img class="card-img" src={artigos[0].imagemArtigo} alt="Card" style={{ objectFit: 'cover', height: '100%', background: 'rgba(168, 170, 174, 0.9)' }} />
                                            <div class="card-img-overlay" style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
                                                <h5 class="card-title text-white">{artigos[0].nomeArtigo}</h5>
                                                <p class="card-text">
                                                    {limitar30Palavras(artigos[0].conteudoArtigo)}
                                                </p>
                                                <div className="d-flex justify-content-between">
                                                    <small className="text-muted"><IconCalendar size={13} /> {formatoDataHora(artigos[0].criadoEm, 'date')}</small>
                                                    <span className="badge bg-primary">{artigos[0].nomeCategoria}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-lg-7">
                                    <Link to={`${location.pathname}/${artigos[1].urlArtigo}`}>
                                        <div className="card mb-3">
                                            <div className="row g-0">
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{artigos[1].nomeArtigo}</h5>
                                                        <p className="card-text">
                                                            {limitar30Palavras(artigos[1].conteudoArtigo)}
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <small className="text-muted"><IconCalendar size={13} /> {formatoDataHora(artigos[1].criadoEm, 'date')}</small>
                                                            <span className="badge bg-primary">{artigos[1].nomeCategoria}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <img className="card-img card-img-right" src={artigos[1].imagemArtigo} alt="Card " style={{ objectFit: 'cover', height: '100%' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={`${location.pathname}/${artigos[2].urlArtigo}`}>
                                        <div className="card mb-3">
                                            <div className="row g-0">
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h5 className="card-title">{artigos[2].nomeArtigo}</h5>
                                                        <p className="card-text">
                                                            {limitar30Palavras(artigos[2].conteudoArtigo)}
                                                        </p>
                                                        <div className="d-flex justify-content-between">
                                                            <small className="text-muted"><IconCalendar size={13} /> {formatoDataHora(artigos[2].criadoEm, 'date')}</small>
                                                            <span className="badge bg-primary">{artigos[1].nomeCategoria}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <img className="card-img card-img-right" src={artigos[2].imagemArtigo} alt="Card " style={{ objectFit: 'cover', height: '100%' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                        )}

                    </div>
                </div>

            </section>

            <section id="artigosBlog" className="section-py landing-features">
                <div className="container">
                    <div className="row mb-5">

                        <div className="col-md-8">
                            {artigos && artigos.length > 3 && (
                                <>
                                    {artigos.slice(3).map((artigo, i) => (
                                        <Link to={`${location.pathname}/${artigo.urlArtigo}`}>
                                            <div className="card mb-3" key={i}>
                                                <div className="row g-0">
                                                    <div className="col-md-3">
                                                        <img className="card-img card-img-left" src={artigo.imagemArtigo} alt="Card " style={{ objectFit: 'cover', height: '100%' }} />
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="card-body">
                                                            <h5 className="card-title">{artigo.nomeArtigo}</h5>
                                                            <p className="card-text">
                                                                {limitar30Palavras(artigo.conteudoArtigo)}
                                                            </p>
                                                            <div className="d-flex justify-content-between">
                                                                <small className="text-muted"><IconCalendar size={13} /> {formatoDataHora(artigo.criadoEm, 'date')}</small>
                                                                <span className="badge bg-primary">{artigo.nomeCategoria}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </>
                            )}

                        </div>

                        <div className="col-md-4">
                            <div className="">
                                {nuvemTags && <BadgeList data={nuvemTags} />}
                            </div>
                        </div>

                    </div>
                </div>

            </section>
        </div>
    )
}

const Badge = ({ name, count }) => {
    const getSizeClass = (count) => {
        if (count >= 5) return 5;
        if (count === 4) return 4;
        if (count === 3) return 3;
        if (count === 2) return 2;
        return 1; // Default size for 1 or fewer articles
    };

    const getSizeText = (count) => {
        if (count >= 5) return 5;
        if (count === 4) return 4;
        if (count === 3) return 4;
        if (count === 2) return 3;
        return 3; // Default size for 1 or fewer articles
    };

    const paddingSize = getSizeClass(count);
    const sizeText = getSizeText(count);

    return (
        <div className={`badge rounded-pill bg-label-primary`} style={{ padding: `${paddingSize/5}rem`, fontSize: `${sizeText/3}em` }}>
            {name} ({count})
        </div>
    );
};

const BadgeList = ({ data }) => {
    console.log(data);
    return (
        <div className="demo-inline-spacing">
            {data.map((tag) => (
                <Badge key={tag.idTag} name={tag.nomeTag} count={tag.numArtigos} />
            ))}
        </div>
    );
};