import React, { useEffect, useState } from "react";

export default function RegistroPerfilEntidade({ dados }) {
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [cnpjEntidade, setCnpj] = useState('');
    const [ieEntidade, setIe] = useState('');
    const [endereco, setEndereco] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cep, setCep] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [uf, setUf] = useState('');
    const [emailEntidade, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');

    const [parametros, setParametros] = useState({
        nomeFantasia: '',
        razaoSocial: '',
        cnpjEntidade: '',
        ieEntidade: '',
        endereco: '',
        complemento: '',
        bairro: '',
        cep: '',
        municipio: '',
        uf: '',
        emailEntidade: '',
        telefone: '',
    });

    useEffect(() => {
        const novosParametros = {
            nomeFantasia,
            razaoSocial,
            cnpjEntidade,
            ieEntidade,
            endereco,
            complemento,
            bairro,
            cep,
            municipio,
            uf,
            emailEntidade,
            telefone,
        };
        setParametros(novosParametros);
    }, [nomeFantasia, razaoSocial, cnpjEntidade, ieEntidade, endereco, complemento, bairro, cep, municipio, uf, emailEntidade, telefone]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            dados(parametros);
        }
    };

    const validateForm = () => {
        const form = document.getElementById("accountDetailsValidation");
        if (!form.checkValidity()) {
            form.classList.add('was-validated');
            return false;
        }
        return true;
    };


    return (
        <form id="accountDetailsValidation" className="content active dstepper-block fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit} noValidate>
            <div className="content-header mb-4">
                <h3 className="mb-1">Registro de Entidade</h3>
                <p>Insira os dados da sua Organização</p>
            </div>
            <div className="row g-3">
                <div className="col-sm-6 fv-plugins-icon-container">
                    <label className="form-label" htmlFor="nomeFantasia">Nome Fantasia</label>
                    <input type="text" name="nomeFantasia" id="nomeFantasia" value={nomeFantasia} onChange={(e) => setNomeFantasia(e.target.value)} className="form-control" placeholder="Comercio ABC" required />

                </div>
                <div className="col-sm-6 fv-plugins-icon-container">
                    <label className="form-label" htmlFor="razaoSocial">Razão Social</label>
                    <input type="text" name="razaoSocial" id="razaoSocial" value={razaoSocial} onChange={(e) => setRazaoSocial(e.target.value)} className="form-control" placeholder="ABC Comercial LTDA" required />

                </div>
                <div className="col-sm-6 fv-plugins-icon-container">
                    <label className="form-label" htmlFor="cnpj">CNPJ</label>
                    <input type="text" id="cnpj" name="cnpj" className="form-control" value={cnpjEntidade} onChange={(e) => setCnpj(e.target.value)} placeholder="00.000.000/0001-00" required />

                </div>
                <div className="col-sm-6 fv-plugins-icon-container">
                    <label className="form-label" htmlFor="ie">I. E.</label>
                    <input type="text" id="ie" name="ie" className="form-control" value={ieEntidade} onChange={(e) => setIe(e.target.value)} placeholder="000.000.000.000" required />

                </div>
                <div className="col-sm-6 fv-plugins-icon-container">
                    <label className="form-label" htmlFor="endereco">Endereço</label>
                    <input type="text" name="endereco" id="endereco" value={endereco} onChange={(e) => setEndereco(e.target.value)} className="form-control" placeholder="Rua São Paulo, 84" required />

                </div>
                <div className="col-sm-3 fv-plugins-icon-container">
                    <label className="form-label" htmlFor="complemento">Complemento</label>
                    <input type="text" name="complemento" id="complemento" value={complemento} onChange={(e) => setComplemento(e.target.value)} className="form-control" placeholder="Galpão A" required />

                </div>
                <div className="col-sm-3 fv-plugins-icon-container">
                    <label className="form-label" htmlFor="bairro">Bairro</label>
                    <input type="text" name="bairro" id="bairro" value={bairro} onChange={(e) => setBairro(e.target.value)} className="form-control" placeholder="São Judas Tadeu" required />

                </div>
                <div className="col-sm-6 fv-plugins-icon-container">
                    <label className="form-label" htmlFor="municipio">Município</label>
                    <input type="text" name="municipio" id="municipio" value={municipio} onChange={(e) => setMunicipio(e.target.value)} className="form-control" placeholder="São Paulo" required />

                </div>
                <div className="col-sm-3 fv-plugins-icon-container">
                    <label className="form-label" htmlFor="uf">UF</label>
                    <select name="uf" id="uf" value={uf} onChange={(e) => setUf(e.target.value)} className="form-control" required>
                        <option value="" disabled>Selecione</option>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                    </select>

                </div>
                <div className="col-sm-3 fv-plugins-icon-container">
                    <label className="form-label" htmlFor="cep">CEP</label>
                    <input type="text" name="cep" id="cep" value={cep} onChange={(e) => setCep(e.target.value)} className="form-control" placeholder="11000-000" required />

                </div>
                <div className="col-sm-6 fv-plugins-icon-container">
                    <label className="form-label" htmlFor="telefone">Telefone</label>
                    <input type="text" name="telefone" id="telefone" value={telefone} onChange={(e) => setTelefone(e.target.value)} className="form-control" placeholder="(00) 0000-0000" required />

                </div>
                <div className="col-sm-6 fv-plugins-icon-container">
                    <label className="form-label" htmlFor="email">E-mail</label>
                    <input type="email" name="email" id="email" value={emailEntidade} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="seu.email@dominio.com.br" required />

                </div>
                <div className="col-12 d-flex justify-content-between mt-4">
                    <button type="submit" className="btn btn-primary btn-next waves-effect waves-light" onClick={handleSubmit}>
                        <span className="align-middle d-sm-inline-block d-none me-sm-1 me-0">Próximo</span>
                    </button>
                </div>
            </div>
        </form>
    )
}
