import React, { useState } from "react";
import CustomEditor from "../../../componentes/Editor";

export default function CriarPost() {
  const [conteudoDoEditor, setConteudoDoEditor] = useState("");

  const handleConteudoEditorChange = (novoConteudo) => {
    setConteudoDoEditor(novoConteudo);
  };

  const handleSubmit = () => {
    console.log("Conteúdo do editor:", conteudoDoEditor);
    // Envie o conteúdo para o servidor, faça outras operações, etc.
  };

  return (
    <div className="row">
      <div className="col-lg-8">
        <div className="card">
          <h5 className="card-header"> Novo Artigo</h5>
          <div className="card-body">
            <CustomEditor value={conteudoDoEditor} onChange={handleConteudoEditorChange} />
          </div>
        </div>
      </div>

      <div className="col-lg-4">
        <div className="card">
          <h5 className="card-header">Opções</h5>
          <div className="card-body">
            <button onClick={handleSubmit}>Enviar</button>
            
          </div>
        </div>
      </div>
    </div>
  );
}
