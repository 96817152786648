import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CustomEditor = ({ value, onChange }) => {
  const [editorState, setEditorState] = useState(value || '');

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link', 'image', 'video'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['blockquote', 'code-block'],
      [{ 'align': [] }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      ['clean']
    ]
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'image', 'video',
    'blockquote', 'code-block',
    'align', 'indent',
    'clean'
  ];

  const handleEditorChange = (content, delta, source, editor) => {
    setEditorState(content);
    if (onChange) {
      onChange(content, delta, editor); // Passando delta e editor para a função onChange
    }
  };

  return (
    <ReactQuill
      value={editorState}
      onChange={handleEditorChange}
      modules={modules}
      formats={formats}
    />
  );
};

export default CustomEditor;
