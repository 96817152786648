import React, { useEffect, useState } from "react";
import { Navigate } from 'react-router-dom';

import Navbar from "../componentes/Menu/NavBar";
import VerticalMenu from "../componentes/Menu/VerticalMenu";

import '../assets/vendor/fonts/fontawesome.css';
import '../assets/vendor/fonts/flag-icons.css';
import '../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css';
import '../assets/vendor/libs/typeahead-js/typeahead.css';
import "../assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css";
import "../assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css";

export default function BaseApp({ children }) {
    const [usuarioAtivo, setUsuarioAtivo] = useState(0);
    const [confirmado, setConfirmado] = useState(null);

    const getPageComponent = () => {
        if (usuarioAtivo === '0') {
            return <Navigate to="/nao-autorizado" />;
        } else if (confirmado === '0') {
            return <Navigate to="/confirmacao" />;
        } else {
            return children;
        }
    }

    useEffect(() => {
        setUsuarioAtivo(localStorage.getItem('ua'));
        setConfirmado(localStorage.getItem('uae'));
    }, []);

    useEffect(() => {
        // Função para alterar as classes da tag <html>
        const changeHtmlClass = (newClass) => {
          document.documentElement.className = newClass;
        };
    
        // Definindo a nova classe
        const newClass = 'light-style layout-navbar-fixed layout-compact layout-menu-fixed';
        changeHtmlClass(newClass);
    
        // Limpeza: restaurar a classe original quando o componente for desmontado
        return () => {
          document.documentElement.className = '';
        };
      }, []); 

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <VerticalMenu />
                <div className="layout-page">
                    <Navbar />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            {getPageComponent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
