import React, { useEffect, useState } from "react";

import joinArrow from "../../../assets/img/front-pages/icons/Join-community-arrow.png";

import laptop from "../../../assets/img/front-pages/icons/laptop.png";
import rocket from "../../../assets/img/front-pages/icons/rocket.png";
import starter from "../../../assets/img/front-pages/icons/paper.png";
import check from "../../../assets/img/front-pages/icons/check.png";
import user from "../../../assets/img/front-pages/icons/user.png";
import docs from "../../../assets/img/front-pages/icons/keyboard.png";
import logo from "../../../assets/img/logo-fns.png"

import { IconCalendar, IconMan, IconStar, IconWoman } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiURL } from "../../../config/s";
import { formatoDataHora, limitar30Palavras } from "../../../componentes/Funcoes/BibliotecaFuncoes";

export default function Home() {

  const [artigos, setArtigos] = useState([]);

  const fetchPosts = async () => {
    const response = await axios.get(`${apiURL}/artigos`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    setArtigos(response.data.resultado);
  }

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div data-bs-spy="scroll" className="scrollspy-example">

      <section id="hero-animation">
        <div id="landingHero" className="section-py landing-hero position-relative" style={{ paddingTop: '200px' }}>
          <div className="container">
            <div className="hero-text-box text-center">
              <h1 className="text-primary display-6 fw-bold">Oportunidades de negócios com recursos públicos</h1>
              <h2 className="hero-sub-title h6 mb-4 pb-1">
                Somos comprometidos com a construção de projetos<br className="d-none d-lg-block" />
                de saúde pública que façam a diferença.
              </h2>
              <div className="landing-hero-btn d-inline-block position-relative">
                <span className="hero-btn-item position-absolute d-none d-md-flex text-heading">Junte-se
                  <img src={joinArrow} alt="Join community arrow" className="scaleX-n1-rtl" /></span>
                <a href="/contato" className="btn btn-primary btn-lg waves-effect waves-light">Contate-nos</a>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section id="landingFeatures" className="section-py landing-features">
        <div className="container">
          <div className="text-center mb-3 pb-1">
            <span className="badge bg-label-primary">Recursos Úteis</span>
          </div>
          <h3 className="text-center mb-1">
            <span className="position-relative fw-bold z-1">Tudo o que você precisa</span> para o seu projeto de Saúde
          </h3>
          <p className="text-center mb-3 mb-md-5 pb-3">
            Do credencimanento, habilitação à captação de recursos e prestação de contas
          </p>
          <div className="features-icon-wrapper row gx-0 gy-4 g-sm-5">
            <div className="col-lg-4 col-sm-6 text-center features-icon-box">
              <div className="text-center mb-3">
                <img src={laptop} alt="laptop charging" />
              </div>
              <h5 className="mb-3">Gestão de Projetos</h5>
              <p className="features-icon-description">
                Temos a maior estrutura de gestão de projetos on-line e real-time.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 text-center features-icon-box">
              <div className="text-center mb-3">
                <img src={rocket} alt="transition up" />
              </div>
              <h5 className="mb-3">Credenciamento de Serviços</h5>
              <p className="features-icon-description">
                Credenciamento e habilitação de novos serviços ambulatoriais e hospitalares.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 text-center features-icon-box">
              <div className="text-center mb-3">
                <img src={starter} alt="edit" />
              </div>
              <h5 className="mb-3">CEBAS Saúde</h5>
              <p className="features-icon-description">
                Assessoria de credenciamento e habilitação da certificação do CEBAS Saúde.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 text-center features-icon-box">
              <div className="text-center mb-3">
                <img src={check} alt="3d select solid" />
              </div>
              <h5 className="mb-3">Reconhecimento</h5>
              <p className="features-icon-description">
                Reconhecidos pela excelência e pela capacidade de resolver os desafios.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 text-center features-icon-box">
              <div className="text-center mb-3">
                <img src={user} alt="lifebelt" />
              </div>
              <h5 className="mb-3">Equipe de Suporte</h5>
              <p className="features-icon-description">
                Uma equipe de profissionais reconhecida pela sua excelência no SUS.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 text-center features-icon-box">
              <div className="text-center mb-3">
                <img src={docs} alt="google docs" />
              </div>
              <h5 className="mb-3">Acesso à Informação</h5>
              <p className="features-icon-description">
                Nossos clientes possuem informações concretas para oportunidades.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="landingReviews" className="section-py bg-body landing-reviews pb-0">
        <div className="container">
          <div className="row align-items-center gx-0 gy-4 g-lg-5">
            <div className="col-md-6 col-lg-5 col-xl-3">
              <div className="mb-3 pb-1">
                <span className="badge bg-label-primary">Avaliações</span>
              </div>
              <h3 className="mb-1">
                <span className="position-relative fw-bold z-1">O que dizem sobre nós

                </span>
              </h3>
              <p className="mb-3 mb-md-5">
                Veja o que nossos parceiros<br className="d-none d-xl-block" />
                e clientes dizem sobre nós.
              </p>
            </div>
            <div className="col-md-6 col-lg-7 col-xl-9">
              {/* Swiper Reviews Carousel */}
              <div className="swiper-reviews-carousel overflow-hidden mb-5 pb-md-2 pb-md-3">
                <div className="swiper swiper-initialized swiper-horizontal swiper-backface-hidden" id="swiper-reviews">
                  <div className="swiper-wrapper" id="swiper-wrapper-b9a50d9ec67f8db6" aria-live="off">
                    {/* Swiper Slides */}
                    {avaliacoes.map((quote, i) => (
                    <div className="swiper-slide swiper-slide-prev" role="group" style={{ width: "322.333px", marginRight: "26px" }} key={i}>
                      <div className="card h-100">
                        <div className="card-body text-body d-flex flex-column justify-content-between h-100">
                          <div className="mb-3">
                            <img src={quote.logo} alt={quote.alt} className="client-logo img-fluid" style={{height: '88px'}}/>
                          </div>
                          <p>{quote.descricao}</p>
                          <div className="text-warning mb-3">
                            <IconStar size={16} />
                            <IconStar size={16} />
                            <IconStar size={16} />
                            <IconStar size={16} />
                            <IconStar size={16} />
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="avatar me-2 avatar-sm">
                              <span className="rounded-circle">{quote.avatar === "masculino" ? <IconMan/> : <IconWoman/>}</span>
                            </div>
                            <div>
                              <h6 className="mb-0">{quote.nome}</h6>
                              <p className="small text-muted mb-0">{quote.cargo}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    ))}
                  </div>
                  {/* Swiper Navigation Buttons */}
                  <div className="swiper-button-next" tabIndex="0" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-b9a50d9ec67f8db6"></div>
                  <div className="swiper-button-prev" tabIndex="0" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-b9a50d9ec67f8db6"></div>
                  <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="blog" style={{ marginTop: '88px' }}>
        <div className="container">
          <div className="text-center mt-4 mb-3 pb-1">
            <span className="badge bg-label-primary">Blog</span>
          </div>
          <h3 className="text-center mb-1">
            <span className="position-relative fw-bold z-1">News &</span> Informação
          </h3>
          <p className="text-center mb-3 mb-md-5 pb-3">
            Atualização e informação certa
          </p>
          <div className="row mb-5">
            <div className="col-md-8">
              {artigos && artigos.length > 0 && (
                <>
                  {artigos.slice(1, 3).map((artigo, i) => (
                    <Link to={`/blog/${artigo.urlArtigo}`} key={i}>
                      <div className="card mb-3">
                        <div className="row g-0">
                          <div className="col-md-3">
                            <img
                              className="card-img card-img-left"
                              src={artigo.imagemArtigo}
                              alt="Card"
                              style={{ objectFit: 'cover', height: '100%' }}
                            />
                          </div>
                          <div className="col-md-9">
                            <div className="card-body">
                              <h5 className="card-title">{artigo.nomeArtigo}</h5>
                              <p className="card-text">
                                {limitar30Palavras(artigo.conteudoArtigo)}
                              </p>
                              <div className="d-flex justify-content-between">
                                <small className="text-muted">
                                  <IconCalendar size={13} /> {formatoDataHora(artigo.criadoEm, 'date')}
                                </small>
                                <span className="badge bg-primary">{artigo.nomeCategoria}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </>
              )}
            </div>


            <div className="col-md-4">
              {artigos && artigos.length > 0 && (
                <>
                  {artigos.slice(0, 1).map((artigo, i) => (
                    <Link to={`/blog/${artigo.urlArtigo}`} key={i}>
                      <div className="card border-2 border-primary mb-3">
                        <div class="card h-100">
                          <img class="card-img-top" src={artigo.imagemArtigo} alt="Card cap" />
                          <div class="card-body">
                            <h5 class="card-title">{artigo.nomeArtigo}</h5>
                            <div className="d-flex justify-content-between">
                              <small className="text-muted">
                                <IconCalendar size={13} /> {formatoDataHora(artigo.criadoEm, 'date')}
                              </small>
                              <span className="badge bg-primary">{artigo.nomeCategoria}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>

      </section>

      <section id="landingReviews" className="section-py bg-body landing-reviews pb-0">
      <div className="text-center mt-4 mb-3 pb-1">
        <span className="badge bg-label-primary">O que Fazemos</span>
        </div>
        <h3 className="text-center mb-1">
        Nossos <span className="position-relative fw-bold z-1">Serviços</span>
        </h3>
        <p className="text-center mb-3 mb-md-5 pb-3">
        Conheça o que fazemos por você!
        </p>
        <div className="container">
          <div className="row align-items-center gx-0 gy-4 g-lg-5">
            <div className="col-md-6 col-lg-5 col-xl-3">
              <div className="mb-3 pb-1">
                <span className="badge bg-label-dark">Gestão de Convênios</span>
              </div>
              <h3 className="mb-1"><span className="position-relative fw-bold z-1">Do Planejamento <br /> à Prestação de Contas</span></h3>
              <p className="mb-3 mb-md-5">Assessoria completa em todas as fases do projeto, seja para habilitação, convênios, emendas ou políticas públicas.</p>
            </div>
            <div className="col-md-6 col-lg-7 col-xl-9">


              <div className="row">
                {servicos.map((ser, i) => (
                  <div className={`col-md-${12 / servicos.length} mb-3`} key={i}>
                    <div className="card h-100">
                      <div className="card-body text-body d-flex flex-column justify-content-between h-100">
                        <div className="mb-3">
                          <img src={ser.logo} alt="client logo" className="client-logo img-fluid" style={{ maxWidth: '143px', maxHeight: '47px' }} />
                        </div>
                        <h6 className="mb-1"><span className="position-relative fw-bold z-1">{ser.titulo}</span></h6>
                        <p>{ser.descricao}</p>
                        <div className="text-warning mb-3">
                          <IconStar size={16} />
                          <IconStar size={16} />
                          <IconStar size={16} />
                          <IconStar size={16} />
                          <IconStar size={16} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

            </div>
          </div>
        </div>
        
        <hr className="m-0" />
        
        <div className="container">
          <div className="swiper-logo-carousel py-4 my-lg-2">
            <div className="swiper swiper-initialized swiper-horizontal swiper-backface-hidden" id="swiper-clients-logos">
              <div className="swiper-wrapper" id="swiper-wrapper-d524a78c5ead7ffb" aria-live="off">
                <div class="swiper-slide swiper-slide-next" role="group" aria-label="2 / 5" style={{ width: "144px" }}>
                  <img src={logo} alt="client logo" class="client-logo" />
                </div>
              </div>
              <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
            </div>
          </div>
        </div>
      </section >

    </div >
  )
}

export const servicos = [
  {
    logo: 'https://www.gov.br/saude/pt-br/composicao/saes/dcebas/logo-cebas.jpg',
    titulo: 'CEBAS Saúde',
    descricao: 'Assessoria e Consultoria para credenciamento e certificação do CEBAS Saúde.',
  },
  {
    logo: 'https://idp.transferegov.sistema.gov.br/idp/resources/imagens/producao/logo_portal.png',
    titulo: 'Transferegov',
    descricao: 'Assessoria na execução do processo licitatório de compras, pagamentos OBTV e Prestação de Contas.',
  },
  {
    logo: 'https://investsus.saude.gov.br/assets/img/investSUS.svg',
    titulo: 'INVESTSUS',
    descricao: 'Assessoria na elaboração e construção de propostas no FNS / INVESTSUS e defesa em diligências.',
  },
  {
    logo: 'https://portalfns.saude.gov.br/wp-content/uploads/2020/07/FNS_MARCA-768x384.png',
    titulo: 'Fundo Nacional de Saúde',
    descricao: 'Assessoria e Consultoria na pactuação Tripartite, desde o credencimanto à habilitação de serviços',
  },
];

export const parcerias = [
  {
    logo: "",
    alt: "",
  },
]

export const avaliacoes = [
  {
    logo: "https://www.santacasadeitatiba.org.br/templates/azul/imagens/logo.png",
    alt: "Logo da santa casa de itatiba",
    descricao: "O trabalho do Instituto Métricas foi indispensável em nossos convênios e principalmente no contrato com a DRS para os serviços de oncologia.",
    avatar: "feminino",
    nome: "Eliane Netto",
    cargo: "Gerente Administrativa"
  },
  {
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRHDzIbnA-ADE8ZFava24Sotc3aH_vAHFTP8r06WdWO6w&s",
    alt: "Logo da santa casa de pompeia",
    descricao: "Realizamos dois convênios de obras e tem sido ideal a assessoria do Instituto, principalmente na orientação do processo licitatório e documental.",
    avatar: "masculino",
    nome: "Claudemir",
    cargo: "Gerente Administrativo"
  },
  {
    logo: "https://santacasajard.org.br/wp-content/uploads/2023/09/cropped-LogoSC-Jardinop-SITE.png",
    alt: "Logo da santa casa de jardinopolis",
    descricao: "A Santa Casa vem lutando para se reestruturar e é muito bom ter o Diego conosco para nos auxiliar na retomada das atividades e para atender vidas.",
    avatar: "masculino",
    nome: "Renê Abbad",
    cargo: "Superintendente"
  }
]