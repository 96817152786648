import React, { useEffect, useState } from "react";

import elemento from "../../../assets/img/autor-diego.jpeg"
import axios from "axios";
import { apiURL } from "../../../config/s";
import { useParams } from 'react-router-dom';
import { formatoDataHora, renderizarHTML } from "../../../componentes/Funcoes/BibliotecaFuncoes";
import PalavrasBadge from "../../../componentes/PalavrasBadge";
import { IconCalendar } from "@tabler/icons-react";
import "./paragrafo.css";


const BlockUI = () => {
    const overlayStyle = {
        zIndex: 1000,
        border: 'none',
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: 'rgb(255, 255, 255)',
        opacity: 1,
        cursor: 'default',
        position: 'absolute',
        display: 'block'
    };

    const spinner = {
        zIndex: 9000,
        border: 'none',
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        cursor: 'default',
        position: 'absolute'
    };

    return (
        <>
            <div className="blockUI blockOverlay" style={overlayStyle}></div>
            <div className="row" style={spinner}>
                <div className="col-xl-12 d-flex align-items-center justify-content-center">
                    <div className="spinner-border spinner-border-lg text-primary" role="status">
                        <span className="visually-hidden">Carregando...</span>
                    </div>
                </div>
            </div>

        </>
    );
};

export default function Post() {

    const { id } = useParams();
    const [artigo, setArtigo] = useState({});
    const [carregando, setCarregando] = useState(false);

    const fetchPost = async () => {
        try {
            setCarregando(true);
            const response = await axios.get(`${apiURL}/artigos/${id}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setArtigo(response.data.resultado[0]);
        } catch (error) {
            console.log(error);
        } finally {
            setCarregando(false);
        }
    }

    useEffect(() => {
        fetchPost();
    }, [id]);
    return (
        <div className="section-py bg-body">
            {artigo && (<>
                <section id="heroBlog">
                    {carregando && <BlockUI />}
                    <div className="container">
                        <div className="row section-py mt-4">
                            <div className="col-12">
                                <div className="card">
                                    <img className="card-img-top" src={artigo.imagemArtigo} alt="Card cap" style={{ maxHeight: '400px', objectFit: 'cover' }} />
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h3>{artigo.nomeArtigo}</h3>
                                            <p className="card-text"><small className="text-muted"><IconCalendar size={13}/> {formatoDataHora(artigo.criadoEm, 'date')}</small></p>
                                        </div>
                                            <span className="badge bg-primary">{artigo.nomeCategoria}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="artigosBlog" className="landing-features">
                    <div className="container">
                        <div className="row mb-5">

                            <div className="col-md-4">
                                <div className="card mb-3">
                                    <div className="row g-0">
                                    <div className="col-md-4">
                                            <img className="card-img card-img-left" src={elemento} alt="Card " />
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body">
                                                <h5 className="card-title">Autor</h5>
                                                <p className="card-text mb-0">Diego Muniz</p>
                                                <p className="card-text"><small className="text-muted">Diretor Executivo</small></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div className="mb-3 px-4 paragrafo lista">
                                    {renderizarHTML(artigo.conteudoArtigo)}
                                </div>
                                <div className="card shadow-none bg-transparent border border-primary mb-3 mt-4">
                                    <div className="card-body">
                                        <h5 className="card-title">Tags</h5>
                                        <p className="card-text">
                                            <PalavrasBadge palavras={artigo.tags} />
                                        </p>
                                    </div>
                                </div>

                                {/*<div className="card mb-3 mt-4">
                                    <div className="card-body">
                                        <h5 className="card-title">Comentários</h5>
                                        <form className="mb-4">
                                            <div className="row g-3">
                                                <div className="col-md-6">
                                                    <div className="form-floating">
                                                        <input type="text" className="form-control" id="nome" placeholder="John Doe" />
                                                        <label htmlFor="nome">Nome</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-floating">
                                                        <input type="email" className="form-control" id="email" placeholder="seu@email.com.br" />
                                                        <label htmlFor="email">E-mail</label>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="basic-default-message">Comentário</label>
                                                    <textarea id="comentario" className="form-control" placeholder="Hi, Do you have a moment to talk Joe?"></textarea>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary waves-effect waves-light">Enviar</button>
                                        </form>

                                        <hr className="py-2" />

                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <div className="avatar me-3 avatar-sm">
                                                <span className="avatar-initial rounded-circle bg-label-primary">DI</span>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">Sara Smith</h6>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse congue auctor iaculis. Sed eget porta elit, nec bibendum tortor.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <div className="avatar me-3 avatar-sm">
                                                <span className="avatar-initial rounded-circle bg-label-primary">DI</span>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">Sara Smith</h6>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse congue auctor iaculis. Sed eget porta elit, nec bibendum tortor.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <div className="avatar me-3 avatar-sm">
                                                <span className="avatar-initial rounded-circle bg-label-primary">DI</span>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">Sara Smith</h6>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse congue auctor iaculis. Sed eget porta elit, nec bibendum tortor.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <div className="avatar me-3 avatar-sm">
                                                <span className="avatar-initial rounded-circle bg-label-primary">DI</span>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">Sara Smith</h6>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse congue auctor iaculis. Sed eget porta elit, nec bibendum tortor.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <div className="avatar me-3 avatar-sm">
                                                <span className="avatar-initial rounded-circle bg-label-primary">DI</span>
                                            </div>
                                            <div>
                                                <h6 className="mb-0">Sara Smith</h6>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse congue auctor iaculis. Sed eget porta elit, nec bibendum tortor.
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>*/}
                            </div>

                        </div>
                    </div>

                </section>
            </>)}
        </div>
    )
}