import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../Logo";
import { IconTool } from "@tabler/icons-react";

export default function WebsiteMenu() {

    const [isScrolled, setIsScrolled] = useState(false);
    const [megaMenuOpen, setMegaMenuOpen] = useState(false);
    const [menuHovered, setMenuHovered] = useState(false);

    const handleMouseEnter = () => {
        setMegaMenuOpen(true);
        setMenuHovered(true);
    };

    const handleMouseLeave = () => {
        setMenuHovered(false);
    };

    const handleMenuLeave = () => {
        setMegaMenuOpen(false);
    };

    useEffect(() => {
        // Função para verificar a posição do scroll
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Adicionar event listener para o evento de scroll
        window.addEventListener('scroll', handleScroll);

        // Limpeza: remover event listener quando o componente for desmontado
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={`layout-navbar shadow-none py-0 ${isScrolled ? 'navbar-active' : ''}`}>
            <div className="container">

                <div className="navbar navbar-expand-lg landing-navbar px-3 px-md-4">

                    <div className="navbar-brand app-brand demo d-flex py-0 py-lg-2 me-4">

                        <button className="navbar-toggler border-0 px-0 me-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="ti ti-menu-2 ti-sm align-middle"></i>
                        </button>

                        <a href="/" className="app-brand-link">
                            <span className="app-brand-logo demo" style={{width: '132px', height: '44px'}}>
                                <Logo/>
                            </span>
                        </a>
                    </div>

                    <div className="collapse navbar-collapse landing-nav-menu" id="navbarSupportedContent">
                        <button className="navbar-toggler border-0 text-heading position-absolute end-0 top-0 scaleX-n1-rtl" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="ti ti-x ti-sm"></i>
                        </button>
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                <NavLink className="nav-link fw-medium" aria-current="page" to={"/"} activeClassName="active">Início</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link fw-medium" to={"/sobre"} activeClassName="active">Sobre</NavLink>
                            </li>
                            <li
                                className={`nav-item mega-dropdown ${megaMenuOpen || menuHovered ? 'show' : ''}`}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Link to={""} className="nav-link dropdown-toggle navbar-ex-14-mega-dropdown mega-dropdown fw-medium" aria-expanded="false" data-bs-toggle="mega-dropdown" data-trigger="hover">
                                    <span data-i18n="Pages">Serviços</span>
                                </Link>
                                <div
                                    className={`dropdown-menu p-4 ${megaMenuOpen || menuHovered ? 'show' : ''}`}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMenuLeave}
                                >
                                    <div className="row gy-4">
                                        <div className="col-12 col-lg">
                                            <div className="h6 d-flex align-items-center mb-2 mb-lg-3">
                                                <div className="avatar avatar-sm flex-shrink-0 me-2">
                                                    <span className="avatar-initial rounded bg-label-primary"><IconTool/></span>
                                                </div>
                                                <span className="ps-1">Serviços</span>
                                            </div>
                                            <ul className="nav flex-column">
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="/servicos/cebas">
                                                        <i className="ti ti-circle me-1"></i>
                                                        <span data-i18n="Pricing">CEBAS Saúde</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="/servicos/investsus">
                                                        <i className="ti ti-circle me-1"></i>
                                                        <span data-i18n="Payment">InvestSUS</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="/servicos/transferegov">
                                                        <i className="ti ti-circle me-1"></i>
                                                        <span data-i18n="Checkout">Transferegov</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        {/*<div className="col-12 col-lg">
                                            <div className="h6 d-flex align-items-center mb-2 mb-lg-3">
                                                <div className="avatar avatar-sm flex-shrink-0 me-2">
                                                    <span className="avatar-initial rounded bg-label-primary"><i className="ti ti-lock-open"></i></span>
                                                </div>
                                                <span className="ps-1">Auth Demo</span>
                                            </div>
                                            <ul className="nav flex-column">
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/auth-login-basic.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Login (Basic)
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/auth-login-cover.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Login (Cover)
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/auth-register-basic.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Register (Basic)
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/auth-register-cover.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Register (Cover)
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/auth-register-multisteps.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Register (Multi-steps)
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/auth-forgot-password-basic.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Forgot Password (Basic)
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/auth-forgot-password-cover.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Forgot Password (Cover)
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/auth-reset-password-basic.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Reset Password (Basic)
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/auth-reset-password-cover.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Reset Password (Cover)
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-12 col-lg">
                                            <div className="h6 d-flex align-items-center mb-2 mb-lg-3">
                                                <div className="avatar avatar-sm flex-shrink-0 me-2">
                                                    <span className="avatar-initial rounded bg-label-primary"><i className="ti ti-file-analytics"></i></span>
                                                </div>
                                                <span className="ps-1">Other</span>
                                            </div>
                                            <ul className="nav flex-column">
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/pages-misc-error.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Error
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/pages-misc-under-maintenance.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Under Maintenance
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/pages-misc-comingsoon.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Coming Soon
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/pages-misc-not-authorized.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Not Authorized
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/auth-verify-email-basic.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Verify Email (Basic)
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/auth-verify-email-cover.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Verify Email (Cover)
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/auth-two-steps-basic.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Two Steps (Basic)
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link mega-dropdown-link" href="../vertical-menu-template/auth-two-steps-cover.html" target="_blank">
                                                        <i className="ti ti-circle me-1"></i>
                                                        Two Steps (Cover)
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4 d-none d-lg-block">
                                            <div className="bg-body nav-img-col p-2">
                                                <img src="../../assets/img/front-pages/misc/nav-item-col-img.png" alt="nav item col " className="w-100" />
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link fw-medium" to={"/blog"} activeClassName="active">Blog</NavLink>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link fw-medium" href="/contato">Contato</a>
                            </li>
                        </ul>
                    </div>
                    <div className="landing-menu-overlay d-lg-none"></div>
                </div>
            </div>
        </nav>
    )
}