import React from "react";
import LogoutBotao from '../../componentes/LogoutBotao/index'
import '../../assets/vendor/libs/@form-validation/form-validation.css'
import '../../assets/vendor/css/pages/page-auth.css'

export default function ConfirmacaoEmail() { 

    return (
        <div className="authentication-wrapper authentication-cover authentication-bg">
            <div className="authentication-inner row">
                <div className="d-none d-lg-flex col-lg-7 p-0">
                    <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                        <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/auth-verify-email-illustration-light.png" 
                            alt="auth-login-cover" 
                            className="img-fluid my-5 auth-illustration" 
                        />

                        <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/bg-shape-image-light.png" 
                            alt="auth-login-cover" 
                            className="platform-bg" 
                        />
                    </div>
                </div>
                <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
                    <div className="w-px-400 mx-auto">
                        <div className="app-brand mb-4">
                            <a href="/" className="app-brand-link gap-2">
                                <span className="app-brand-logo demo">
                                    <svg width="32" height="22" viewBox="0 0 32 22" fillRule="none" xmlns="http://www.w3.org/2000/svg" style={{rotate: "180deg"}}>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z" fill="#7367F0"></path>
                                        <path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z" fill="#161616"></path>
                                        <path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z" fill="#161616"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z" fill="#7367F0"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>

                        <h3 className="mb-4">Verifique o seu email ✉️</h3>
                        <p className="text-start mb-4">
                            Link de ativação da conta enviado para seu endereço de e-mail. Siga o link interno para continuar.
                        </p>
                        <LogoutBotao />

                        <p className="text-center">
                            <span>Não recebeu o e-mail?</span>
                            <a href="auth-register-cover.html">
                                &nbsp;<span>Reencaminhar</span>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}