import { IconArrowUp } from '@tabler/icons-react';
import React, { useState, useEffect } from 'react';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div>
            {isVisible && (
                <button onClick={scrollToTop} className={`btn rounded-pill btn-icon btn-primary waves-effect waves-light`} style={styles.button}>
                    <IconArrowUp size={21}/>
                </button>
            )}
        </div>
    );
};

export default ScrollToTopButton;

const styles = {
    button: {
        position: 'fixed',
        bottom: '50px',
        right: '50px',
        zIndex: 1000,
    }
};