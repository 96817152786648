const PalavrasBadge = ({ palavras }) => {
    const palavrasArray = String(palavras).split(',');
    console.log(palavrasArray);

    return (
        <div className="demo-inline-spacing">
            {palavrasArray.map((palavra, index) => (
                <span key={index} className="badge bg-label-primary">#{palavra.trim()}</span>
            ))}
        </div>
    );
};

export default PalavrasBadge;
