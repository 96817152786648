/* eslint-disable jsx-a11y/anchor-is-valid */
import { IconLogout } from '@tabler/icons-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function LogoutMenu() {
    const navigate = useNavigate();

    const handleLogout = () => {

        localStorage.clear();

        navigate('/login');
    };

    return (
        <a className="dropdown-item" href="/login" onClick={handleLogout}>
            <IconLogout size={16} /> &nbsp;
            <span className="align-middle">Sair</span>
        </a>
    );
};
