/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

function VerticalMenu() {
  const [menuCollapsed, setMenuCollapsed] = useState(false);

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem("collapsed");
    if (collapsedFromStorage !== null) {
      setMenuCollapsed(collapsedFromStorage === "true");
      updateHtmlClasses(collapsedFromStorage === "true");
    }
  }, []);

  const toggleMenu = () => {
    const newMenuCollapsed = !menuCollapsed;
    localStorage.setItem("collapsed", newMenuCollapsed);
    setMenuCollapsed(newMenuCollapsed);
    updateHtmlClasses(newMenuCollapsed);
  };

  const updateHtmlClasses = (menuCollapsed) => {
    const htmlElement = document.querySelector('html');
    if (htmlElement) {
      htmlElement.classList.toggle('layout-compact', menuCollapsed);
      htmlElement.classList.toggle('layout-menu-collapsed', menuCollapsed);
    }
  };

  const handleMouseEnter = () => {
    const htmlElement = document.querySelector('html');
    if (htmlElement) {
      htmlElement.classList.add('layout-menu-hover');
    }
  };

  const handleMouseLeave = () => {
    const htmlElement = document.querySelector('html');
    if (htmlElement) {
      htmlElement.classList.remove('layout-menu-hover');
    }
  };

  return (
    <aside id="layout-menu"
      className={`layout-menu menu-vertical menu bg-menu-theme ${menuCollapsed ? 'menu-collapsed' : ''}`}
      data-bg-class="bg-menu-theme"
      style={{ touchAction: 'none', userSelect: 'none', WebkitUserDrag: 'none', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}
      {...(menuCollapsed === true ? { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave } : {})}
    >
      <div className="app-brand demo">
        <a href="index.html" className="app-brand-link">
          <span className="app-brand-logo demo">
            <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z" fill="#7367F0"></path>
              <path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z" fill="#161616"></path>
              <path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z" fill="#161616"></path>
              <path fillRule="evenodd" clipRule="evenodd" d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z" fill="#7367F0"></path>
            </svg>
          </span>
          <span className="app-brand-text demo menu-text fw-bold">Vuexy</span>
        </a>

        <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto" onClick={toggleMenu}>
          <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
          <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
        </a>
      </div>

      <div className="menu-inner-shadow"></div>

      <ul className="menu-inner py-1 ps ps--active-y">
        <li className="menu-item active open">
          <a href="javascript:void(0);" className="menu-link menu-toggle">
            <i className="menu-icon tf-icons ti ti-smart-home"></i>
            <div data-i18n="Dashboards">Dashboards</div>
            <div className="badge bg-primary rounded-pill ms-auto">5</div>
          </a>
          <ul className="menu-sub">
            <li className="menu-item">
              <a href="index.html" className="menu-link">
                <div data-i18n="Analytics">Analytics</div>
              </a>
            </li>
            <li className="menu-item active">
              <a href="dashboards-crm.html" className="menu-link">
                <div data-i18n="CRM">CRM</div>
              </a>
            </li>
            <li className="menu-item">
              <a href="app-ecommerce-dashboard.html" className="menu-link">
                <div data-i18n="eCommerce">eCommerce</div>
              </a>
            </li>
            <li className="menu-item">
              <a href="app-logistics-dashboard.html" className="menu-link">
                <div data-i18n="Logistics">Logistics</div>
              </a>
            </li>
            <li className="menu-item">
              <a href="app-academy-dashboard.html" className="menu-link">
                <div data-i18n="Academy">Academy</div>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </aside>
  );
}

export default VerticalMenu;
