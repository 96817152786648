/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import { IconUser } from '@tabler/icons-react';
import React from 'react';
import LogoutMenu from '../../LogoutBotao/LogoutMenu';

function Navbar() {
  return (
    <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a className="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
          <i className="ti ti-menu-2 ti-sm"></i>
        </a>
      </div>

      <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
        <div className="navbar-nav align-items-center">
          <div className="nav-item navbar-search-wrapper mb-0">
            <a className="nav-item nav-link search-toggler d-flex align-items-center px-0" href="javascript:void(0);">
              <i className="ti ti-search ti-md me-2"></i>
              <span className="d-none d-md-inline-block text-muted">Search (Ctrl+/)</span>
            </a>
          </div>
        </div>

        <ul className="navbar-nav flex-row align-items-center ms-auto">
          {/* Additional items can be included here */}
          <li className="nav-item navbar-dropdown dropdown-user dropdown">
            <a className="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
              <div className="avatar avatar-online">
                <img src="../../assets/img/avatars/1.png" alt="" className="h-auto rounded-circle" />
              </div>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <a className="dropdown-item" href="pages-account-settings-account.html">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar avatar-online">
                        <img src="../../assets/img/avatars/1.png" alt="" className="h-auto rounded-circle" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <span className="fw-medium d-block">John Doe</span>
                      <small className="text-muted">Admin</small>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <a className="dropdown-item" href="/meu-perfil">
                  <IconUser size={16} /> &nbsp;
                  <span className="align-middle">Meu Perfil</span>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="pages-account-settings-account.html">
                  <i className="ti ti-settings me-2 ti-sm"></i>
                  <span className="align-middle">Settings</span>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="pages-account-settings-billing.html">
                  <span className="d-flex align-items-center align-middle">
                    <i className="flex-shrink-0 ti ti-credit-card me-2 ti-sm"></i>
                    <span className="flex-grow-1 align-middle">Billing</span>
                    <span className="flex-shrink-0 badge badge-center rounded-pill bg-label-danger w-px-20 h-px-20">2</span>
                  </span>
                </a>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <a className="dropdown-item" href="pages-faq.html">
                  <i className="ti ti-help me-2 ti-sm"></i>
                  <span className="align-middle">FAQ</span>
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="pages-pricing.html">
                  <i className="ti ti-currency-dollar me-2 ti-sm"></i>
                  <span className="align-middle">Pricing</span>
                </a>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <LogoutMenu/>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="navbar-search-wrapper search-input-wrapper d-none">
        <span className="twitter-typeahead" style={{ position: 'relative', display: 'inline-block' }}>
          <input type="text" className="form-control search-input container-xxl border-0 tt-input" placeholder="Search..." aria-label="Search..." autoComplete="off" spellCheck="false" dir="auto" style={{ position: 'relative', verticalAlign: 'top' }} />
          <pre aria-hidden="true" style={{ position: 'absolute', visibility: 'hidden', whiteSpace: 'pre', fontFamily: '"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif', fontSize: '15px', fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, wordSpacing: '0px', letterSpacing: '0px', textIndent: '0px', textRendering: 'auto', textTransform: 'none' }}></pre>
          <div className="tt-menu navbar-search-suggestion ps" style={{ position: 'absolute', top: '100%', left: '0px', zIndex: 100, display: 'none' }}>
            <div className="tt-dataset tt-dataset-pages"></div>
            <div className="tt-dataset tt-dataset-files"></div>
            <div className="tt-dataset tt-dataset-members"></div>
            <div className="ps__rail-x" style={{ left: '0px', bottom: '0px' }}><div className="ps__thumb-x" tabIndex="0" style={{ left: '0px', width: '0px' }}></div></div>
            <div className="ps__rail-y" style={{ top: '0px', right: '0px' }}><div className="ps__thumb-y" tabIndex="0" style={{ top: '0px', height: '0px' }}></div></div>
          </div>
        </span>
        <i className="ti ti-x ti-sm search-toggler cursor-pointer"></i>
      </div>
    </nav>
  );
}

export default Navbar;

