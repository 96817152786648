import React, { useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import { apiURL } from './config/s';
import useAuth from './middleware/auth';

import { PerfilContexto, ProvedorDeContexto } from './context/PerfilContexto';

import Login from './pages/Login';
import Registro from './pages/Registro';
import EsqueceuSenha from './pages/EsqueceuSenha';
import ResetSenha from './pages/ResetSenha';
import NaoAutorizado from './pages/NaoAutorizado';
import ConfirmacaoEmail from './pages/ConfirmacaoEmail';
import Confirmar from './pages/Confirmar';
import Home from './pages/Website/Home';
import RegistroPerfilempresa from './pages/RegistroPerfilEmpresa';
import BaseWebsite from './pages/BaseWebSite.jsx';
import Sobre from './pages/Website/Sobre/index.jsx';
import Blog from './pages/Website/Blog/index.jsx';
import Post from './pages/Website/Post/index.jsx';
import BaseApp from './pages/BaseApp.jsx';
import Painel from './pages/App/Painel/index.jsx';
import MeuPerfil from './pages/App/MeuPerfil/index.jsx';
import CriarPost from './pages/App/CriarPost/index.jsx';
import PaginaNaoEncontrada from './pages/Website/PaginaNaoEncontrada/index.jsx';
import ScrollToTop from './componentes/ScrollToTop/index.jsx';
import Contato from './pages/Website/Contato/index.jsx';
import Cebas from './pages/Website/Servicos/Cebas/index.jsx';
import InvestSus from './pages/Website/Servicos/InvestSus/index.jsx';
import Transferegov from './pages/Website/Servicos/Transferegov/index.jsx';
import TermosPrivacidade from './pages/Website/TermosPrivacidade/index.jsx';

const RotasSuperAdministrador = ({ token }) => (
  <>
    <Route path="/app/painel" element={token ? <BaseApp><Painel /></BaseApp> : <Navigate to="/app/login" />} />
    <Route path="/app/meu-perfil" element={token ? <BaseApp><MeuPerfil /></BaseApp> : <Navigate to="/app/login" />} />
    <Route path="/app/novo-post" element={token ? <BaseApp><CriarPost /></BaseApp> : <Navigate to="/app/login" />} />
  </>
);

const RotasUsuarios = ({ token }) => (
  <>
    <Route path="/app/painel" element={token ? <BaseApp><Painel /></BaseApp> : <Navigate to="/app/login" />} />
    <Route path="/app/meu-perfil" element={token ? <BaseApp><MeuPerfil /></BaseApp> : <Navigate to="/app/login" />} />
    <Route path="/app/novo-post" element={token ? <BaseApp><CriarPost /></BaseApp> : <Navigate to="/app/login" />} />
  </>
);

const AppRoutes = ({ token, perfil, handleLogin }) => (
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<BaseWebsite><Home /></BaseWebsite>} />
      <Route path="/sobre" element={<BaseWebsite><Sobre /></BaseWebsite>} />
      <Route path="/blog" element={<BaseWebsite><Blog /></BaseWebsite>} />
      <Route path="/blog/:id" element={<BaseWebsite><Post /></BaseWebsite>} />
      <Route path="/contato" element={<BaseWebsite><Contato /></BaseWebsite>} />
      <Route path="/servicos/cebas" element={<BaseWebsite><Cebas /></BaseWebsite>} />
      <Route path="/servicos/investsus" element={<BaseWebsite><InvestSus /></BaseWebsite>} />
      <Route path="/servicos/transferegov" element={<BaseWebsite><Transferegov /></BaseWebsite>} />
      <Route path="/termos-de-privacidade" element={<BaseWebsite><TermosPrivacidade /></BaseWebsite>} />
      <Route path="/painel" element={<h1>Painel</h1>} />

      <Route path="/app/login" element={token ? <Navigate to="/app/painel" /> : <Login handleLogin={handleLogin} />} />
      <Route path="/app/registro" element={<Registro />} />
      <Route path="/app/confirmacao" element={<ConfirmacaoEmail />} />
      <Route path="/app/confirmar/:token" element={<Confirmar />} />
      <Route path="/app/nao-autorizado" element={<NaoAutorizado />} />
      <Route path="/app/esqueceu-senha" element={<EsqueceuSenha />} />
      <Route path="/app/reset-senha/:token" element={<ResetSenha />} />
      <Route path="/app/perfil-empresa" element={token ? <RegistroPerfilempresa /> : <Navigate to="/app/login" />} />

      <React.Fragment>{RotasSuperAdministrador({ token })}</React.Fragment>
      <React.Fragment>{RotasUsuarios({ token })}</React.Fragment>

      <Route path="/404" element={<BaseWebsite><PaginaNaoEncontrada /></BaseWebsite>} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  </BrowserRouter>
);

function App() {
  const { token, login, logout } = useAuth();
  const { perfil, setPerfil } = useContext(PerfilContexto);

  const handleLogin = async (email, senha) => {
    try {
      const response = await axios.post(`${apiURL}/login`, {
        email,
        senha,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response && response.data) {
        const data = response.data;
        console.log(data);

        const accessToken = data.accessToken;
        const expiresIn = data.expiresIn;

        if (accessToken && expiresIn) {
          login(accessToken);

          let expiryTime;
          if (typeof expiresIn === 'string' && expiresIn.endsWith('m')) {
            const minutes = parseInt(expiresIn.replace('m', ''), 10);
            expiryTime = new Date().getTime() + minutes * 60 * 1000;
          } else if (typeof expiresIn === 'number') {
            expiryTime = new Date().getTime() + expiresIn * 1000;
          } else {
            throw new Error('Invalid expiresIn format');
          }

          localStorage.setItem('id', data.id);
          localStorage.setItem('ex', expiryTime);
          localStorage.setItem('ua', data.ativo);
          localStorage.setItem('uae', data.confirmacao);
          localStorage.setItem('usuario', data.perfil);
          localStorage.setItem('prl', data.Prl);
          setPerfil(data.Prl);

          setTimeout(() => {
            logout();
            localStorage.clear();
          }, expiryTime - new Date().getTime());

          return {
            id: data.id,
            ex: expiryTime
          };
        } else {
          throw new Error('Access token or expiresIn is missing in the response');
        }
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      throw error;
    }
  };

  useEffect(() => {
    const expiresIn = localStorage.getItem('ex');
    if (expiresIn) {
      const currentTime = new Date().getTime();
      if (currentTime > expiresIn) {
        logout();
        localStorage.clear();
      } else {
        setTimeout(() => {
          logout();
          localStorage.clear();
        }, expiresIn - currentTime);
      }
    }
  }, [logout]);

  return <AppRoutes token={token} perfil={perfil} handleLogin={handleLogin} />;
}

const AppComContexto = () => (
  <ProvedorDeContexto>
    <App />
  </ProvedorDeContexto>
);

export default AppComContexto;
