/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import axios from "axios";
import React, { useEffect } from "react";
import { apiURL } from "../../../config/s";

export default function Painel() {

    const fetchUsuario = async (id, token) => {
        try {
            const response = await axios.get(`${apiURL}/usuarios/${id}`, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log(response.data);
        } catch (error) {
            console.error('Erro ao buscar usuário:', error);
        }
    };

    useEffect(() => {
        const id = localStorage.getItem('id');
        const token = localStorage.getItem('_');
        if (id && token) {
            fetchUsuario(id, token);
        } else {
            console.error('ID ou token de autorização ausente no sessionStorage');
        }
    }, []);

    return (
        <>
            <h4 className="py-3 mb-2">
                <span className="text-muted fw-light">Painel Principal /</span> Processos Licitatórios
            </h4>
            <div className="card">
                <div className="card-datatable table-responsive">
                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                        <div className="card-header pb-md-2 d-flex flex-column flex-md-row align-items-start align-items-md-center"><div>
                            <div id="DataTables_Table_0_filter" className="dataTables_filter ms-n3">
                                <label>
                                    <input type="search" className="form-control" placeholder="Search Order" aria-controls="DataTables_Table_0" />
                                </label>
                            </div>
                        </div>
                            <div className="d-flex align-items-md-center justify-content-md-end mt-2 mt-md-0 gap-2">
                                <div className="dataTables_length mt-0 mt-md-3 ms-n2" id="DataTables_Table_0_length">
                                    <label>
                                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="form-select">
                                            <option value="10">10</option>
                                            <option value="40">40</option>
                                            <option value="60">60</option>
                                            <option value="80">80</option>
                                            <option value="100">100</option>
                                        </select>
                                    </label>
                                </div>
                                <div className="dt-action-buttons pt-0">
                                    <div className="dt-buttons btn-group flex-wrap">
                                        <div className="btn-group">
                                            <button className="btn btn-secondary buttons-collection dropdown-toggle btn-label-secondary waves-effect waves-light" tabIndex="0" aria-controls="DataTables_Table_0" type="button" aria-haspopup="dialog" aria-expanded="false">
                                                <span><i className="ti ti-download me-1"></i>Exportar</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="datatables-order table border-top dataTable no-footer dtr-column collapsed">
                            <thead>
                                <tr>
                                    <th>XXXXXX</th>
                                    <th>XXXXXX</th>
                                    <th>XXXXXX</th>
                                    <th>XXXXXX</th>
                                    <th>XXXXXX</th>
                                    <th>XXXXXX</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="odd">
                                    <td>XXXXXX</td>
                                    <td>XXXXXX</td>
                                    <td>XXXXXX</td>
                                    <td>XXXXXX</td>
                                    <td>XXXXXX</td>
                                    <td>XXXXXX</td>
                                </tr>
                                <tr className="even">
                                    <td>XXXXXX</td>
                                    <td>XXXXXX</td>
                                    <td>XXXXXX</td>
                                    <td>XXXXXX</td>
                                    <td>XXXXXX</td>
                                    <td>XXXXXX</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="row mx-2 my-2">
                            <div className="col-sm-12 col-md-6">
                                <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                    Displaying 1 to 10 of 100 entries
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                jasdklajsdlk
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}