/* eslint-disable no-script-url */
import React from "react";
import { Link } from "react-router-dom";

import footerBanner from "../../assets/img/front-pages/backgrounds/footer-bg-light.png"
import LogoBranco from "../LogoBranco";
import Inscricao from "./Inscricao";
import { IconBrandInstagram, IconBrandLinkedin } from "@tabler/icons-react";
import instagram from "../../assets/img/post-instagram.jpg"

export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="landing-footer bg-body footer-text">
            <div className="footer-top position-relative overflow-hidden z-1">
                <img src={footerBanner} alt="footer bg" className="footer-bg banner-bg-img z-n1" />
                <div className="container">
                    <div className="row gx-0 gy-4 g-md-5">
                        <div className="col-lg-5">
                            <Link to="/" className="app-brand-link mb-4">
                                <span className="app-brand-logo demo" style={{ width: "132px", height: "44px" }}>
                                    <LogoBranco />
                                </span>
                            </Link>
                            <p className="footer-text footer-logo-description mb-4">
                                Medindo o progresso,<br />transformando realidades.
                            </p>
                            <Inscricao />
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <h6 className="footer-title mb-4">Utilidades</h6>
                            <ul className="list-unstyled">
                                <li className="mb-3">
                                    <Link to="/sobre" target="_blank" className="footer-link">Sobre o Instituto</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to="/blog" target="_blank" className="footer-link">Notícias e Informação</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to="/cebas" target="_blank" className="footer-link">CEBAS Saúde</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to="/investsus" target="_blank" className="footer-link">InvestSUS</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to="/transferegov" target="_blank" className="footer-link">Transferegov</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6">
                            <h6 className="footer-title mb-4">Páginas</h6>
                            <ul className="list-unstyled">
                                <li className="mb-3">
                                    <Link to="/termos-de-privacidade" className="footer-link">Termos de Privacidade</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to="/contato" className="footer-link">Contato</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to="https://metricas.org" className="footer-link">Métricas</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to="#" className="footer-link">ASAS<span class="badge rounded bg-primary ms-2">Em Breve</span></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-4">
                            <h6 className="footer-title mb-4">Acesse</h6>
                            <Link to="https://www.instagram.com/p/C30jt52AZHH/?igsh=aTI0NnJoeDc2eGt5" target="_blank" style={{ display: 'block', width: '80%', height: '80%' }}>
                                <img src={instagram} alt="post do instagram" style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }} />
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom py-3">
                <div className="container d-flex flex-wrap justify-content-between flex-md-row flex-column text-center text-md-start">
                    <div className="mb-2 mb-md-0">
                        <span className="footer-text">©
                            {currentYear}
                        </span> <Link to="https://institutometricas.org.br" target="_blank" className="fw-medium text-white footer-link">Instituto Métricas,</Link>
                        <span className="footer-text"> Feito para melhorar a comunicação.</span>
                    </div>
                    <div>
                        <Link to="https://www.linkedin.com/company/institutometricas" className="footer-link me-3" target="_blank">
                            <span className="text-white"><IconBrandLinkedin size={32} /> Linkedin</span>
                        </Link>
                        <Link to="https://www.instagram.com/instituto_metricas/" className="footer-link" target="_blank">
                            <span className="text-white"><IconBrandInstagram size={32} /> Instagram</span>
                        </Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}