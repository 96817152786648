import { IconMathLower } from "@tabler/icons-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiURL } from "../../config/s";
import LoadingMenor from "../../componentes/Loading/LoadingMenor";
import '../../assets/vendor/libs/@form-validation/form-validation.css'
import '../../assets/vendor/css/pages/page-auth.css'

export default function ResetSenha() {
    const [senha, setSenha] = useState('');
    const [confirmaSenha, setConfirmaSenha] = useState('');
    const [senhaMatchError, setSenhaMatchError] = useState('');
    const [error, setError] = useState('');
    const [carregando, setCarregando] = useState(false);

    const { token } = useParams();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (senha !== confirmaSenha) {
            setSenhaMatchError('As senhas não coincidem');
            return;
        }
        try {
            await postSenha(token);
        } catch (error) {
            setError(error.response?.data?.error || 'Erro ao processar sua solicitação');
        }
    };

    const postSenha = async (token) => {
        try {
            setCarregando(true);
            const response = await axios.put(`${apiURL}/usuarios/atualizar-senha/${token}`, { senha }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.data && response.data.error) {
                setError(response.data.error);
            } else if (response.data && response.data.usuario) {
                setTimeout(() => {
                    console.log(response.data.usuario);
                    navigate('/login');
                }, 1000);
            }
        } catch (error) {
            setError(error.response?.data?.error || 'Erro ao processar sua solicitação');
        } finally {
            setCarregando(false);
        }
    };

    useEffect(() => {
        const isValidParam = (param) => {
            return typeof param === 'string' && param.trim().length > 0;
        };

        if (!isValidParam(token)) {
            setError('Parâmetro inválido');
        }
    }, [token]);

    return (
        <div className="authentication-wrapper authentication-cover authentication-bg">
            <div className="authentication-inner row">
                <div className="d-none d-lg-flex col-lg-7 p-0">
                    <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                        <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/auth-reset-password-illustration-light.png"
                            alt="auth-login-cover"
                            className="img-fluid my-5 auth-illustration"
                        />
                        <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/illustrations/bg-shape-image-light.png"
                            alt="auth-login-cover"
                            className="platform-bg"
                        />
                    </div>
                </div>
                <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
                    <div className="w-px-400 mx-auto">
                        <div className="app-brand mb-4">
                            <a href="/" className="app-brand-link gap-2">
                                <span className="app-brand-logo demo">
                                    <svg width="32" height="22" viewBox="0 0 32 22" fillRule="none" xmlns="http://www.w3.org/2000/svg" style={{ rotate: "180deg" }}>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z" fill="#7367F0"></path>
                                        <path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z" fill="#161616"></path>
                                        <path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z" fill="#161616"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z" fill="#7367F0"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>

                        <h3 className="mb-4">Reset Senha 🔒</h3>
                        <p className="mb-4">for <span className="fw-medium">john.doe@email.com</span></p>

                        {senhaMatchError && <div className="alert alert-danger" role="alert">{senhaMatchError}</div>}

                        <form id="formAuthentication" className="mb-3 fv-plugins-bootstrap5 fv-plugins-framework" noValidate="novalidate" onSubmit={handleSubmit}>
                            <div className="mb-3 form-password-toggle fv-plugins-icon-container">
                                <label className="form-label" htmlFor="password">Senha</label>
                                <div className="input-group input-group-merge has-validation">
                                    <input type="password" id="senha" className="form-control" name="senha" placeholder="············" aria-describedby="password" onChange={(e) => setSenha(e.target.value)} />
                                    <span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>
                                </div>
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                            </div>
                            <div className="mb-3 form-password-toggle fv-plugins-icon-container">
                                <label className="form-label" htmlFor="passwordConfirmation">Confirmar Senha</label>
                                <div className="input-group input-group-merge has-validation">
                                    <input type="password" id="passwordConfirmation" className="form-control" name="passwordConfirmation" placeholder="············" aria-describedby="passwordConfirmation" onChange={(e) => setConfirmaSenha(e.target.value)} />
                                    <span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>
                                </div>
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                            </div>
                            <button className="btn btn-primary d-grid w-100 mb-3 waves-effect waves-light">
                                Salvar Nova Senha
                            </button>
                            <input type="hidden" />
                        </form>
                        {carregando ? (
                            <LoadingMenor />
                        ) : (
                            error ? (
                                <div className="alert alert-danger" role="alert">{error}</div>
                            ) : (
                                <div className="alert alert-success" role="alert">Senha redefinida com sucesso!</div>
                            )
                        )}

                        <div className="text-center">
                            <a href="/" className="d-flex align-items-center justify-content-center">
                                <IconMathLower size={16} />
                                Voltar para login
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
