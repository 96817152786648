import React from 'react';

export default function LoadingMenor() {
    return (
        <div className="row justify-content-center py-5">
            <div className="col-xl-6 mb-4 mb-xl-0">
                <center>
                    <div className="spinner-border spinner-border-lg text-primary" role="status">
                        <span className="visually-hidden text-center">Carregando...</span>
                    </div>
                </center>
            </div>
        </div>
    );
};
