// useAuth.js
import { useState } from 'react';

export default function useAuth() {
  const [token, setToken] = useState(localStorage.getItem('_') || null);

  const login = (accessToken) => {
    setToken(accessToken);
    localStorage.setItem('_', accessToken);
  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem('id');
    localStorage.removeItem('ex');
    localStorage.removeItem('ua');
    localStorage.removeItem('usuario');
    localStorage.removeItem('prl');
  };

  return {
    token,
    login,
    logout,
  };
}
